import { createApp } from 'vue'
import App from './App.vue'
import router from './router' 

import axios from "axios";
import VueAxios from 'vue-axios'

window.axios = require('axios');
//axios.defaults.baseURL = "https://cdn.tycoonmach.net/api/"
axios.defaults.baseURL = "/api/"
axios.defaults.headers.get['Accepts'] = 'application/json'



  createApp(App).use(VueAxios, axios).use(router).mount('#app')

