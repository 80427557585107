<template>
    <div>
        <loading v-if="showLoadingPage"></loading>
        <acceptedScreen v-if="accepted" />
        <rejectedScreen v-if="rejected" />
        <HeaderComponent />
        <div class="wf-section" style="height:1080px !important;">
            <div class="form-step-2" style="padding-top:0px;">
                <div class="question-container w-container" style="margin-bottom:25px;">
                    <h1 class="question-2" style="margin-bottom:12px;"><strong class="bold-text-3">Please enter <br class="mobile-newline">info below</strong><br></h1>
                    <p class="text-block-45" style="color:red;font-size:16px;" v-if="vendorErr==1">Your form link doesn't look correct. Please use the link on your dashboard. <a href="https://dashboard.leadtrack.app/login" target="_blank">Click here</a></p>
                </div>
                <div class="container-10 w-container" style="height:90vh;">
                    <div class="w-form">
                        <transition-group name="list" tag="div">

                            <div v-if="active_el==1">
                                <form @submit.prevent="SearchAddressData">
                                    <div class="" v-if="showError" style="color:red;">
                                        <p>{{messageErr}}</p>
                                    </div>
                                    <div class="div-block-150">
                                        <label> Name </label>
                                    </div>
                                    <div class="div-block-150">
                                        <input type="text" class="text-field-12 w-input" maxlength="256" v-model="data.first_name" data-name="First Name" placeholder="First Name" required>
                                        <input type="text" class="text-field-13 w-input" maxlength="256" v-model="data.last_name" data-name="Last Name" placeholder="Last Name" id="Last-Name" required>
                                    </div>
                                    <div class="div-block-150">
                                        <label> Phone </label>
                                    </div>
                                    <input type="number" v-model="data.phone" class="text-field-15 w-input" placeholder="Enter Phone" required>
                                    <div class="div-block-150">
                                    <label> Select State </label>
                                    </div>
                                    <div class="div-block-150">
                                        <select class="text-field-12 w-input" v-model="data.state" @change="selectChange()" required>
                                            <option value="" disabled >Select State</option>
                                                <!--<option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District Of Columbia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>-->
                                                <option value="IL">Illinois</option>
                                                <!--<option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>-->
                                                <option value="KY">Kentucky</option>
                                                <!--<option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>-->
                                                <option value="MI">Michigan</option>
                                                <!--<option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>-->
                                                <!--<option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>-->
                                                <option value="NY">New York</option>
                                                <!--<option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>-->
                                                <option value="OH">Ohio</option>
                                                <!--<option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>-->
                                                <option value="PA">Pennsylvania</option>
                                                <!--<option value="PR">Puerto Rico</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>-->
                                        </select>
                                    </div>
                                    <div class="div-block-150">
                                        <label> Medicaid Number </label>
                                    </div>
                                    <input type="text" v-model="data.medicare" class="text-field-15 w-input" placeholder="Medicaid Number">
                                    <div v-if="isSSNState">
                                        <div class="div-block-150" >
                                            <label> OR Enter SSN </label>
                                        </div>
                                        <input type="text" maxlength="9" v-model="data.ssn" class="text-field-15 w-input" placeholder="Enter SSN">
                                    </div>

                                    <div class="div-block-150">
                                        <label> Date of Birth </label>
                                    </div>
                                    <div class="div-block-150">
                                        <input type="number" v-model="data.mm" max="12" min="1" class="text-field-12 w-input" maxlength="2" minlength="2" placeholder="MM" required>
                                        <input type="number" v-model="data.dd" max="31" min="1" class="text-field-12 w-input" maxlength="2" minlength="2" placeholder="DD" required>
                                        <input type="number" v-model="data.yyyy" max="2023" min="1901" class="text-field-13 w-input" maxlength="4" minlength="4" placeholder="YYYY" required>
                                    </div>
                                    

                                    <div class="div-block-150">
                                        <label> Gender </label>
                                    </div>
                                    <div class="div-block-150">
                                        <select class="text-field-12 w-input" v-model="data.gender">
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                    <div class="div-block-274">
                                        <button type="submit" class="button-22 w-button">Submit</button>
                                    </div>
                                    
                                </form>
                            </div>
                            
                        </transition-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <FooterComponent />
    </template>
    
    <script>
    import HeaderComponent from '../common/headerComponent.vue'
    import FooterComponent from '../common/footerComponent.vue'
    import acceptedScreen from '../common/acceptedScreen.vue'
    import googleautocomplete from '../common/googleAddressNew.vue'
    import loading from '../common/newLoading.vue'
    import rejectedScreen from '../common/rejectedScreen.vue'
    export default {
        name: 'HelloWorld',
    
        components: {
            HeaderComponent,
            FooterComponent,
            googleautocomplete,
            loading,
            acceptedScreen,
            rejectedScreen
        },
        data() {
            return {
                ssnStates:["NM", "OK", "DE", "IA", "DC", "FL", "MN", "PA", "WY", "MI", "SC", "NE", "AL", "CO", "CT", "ID", "IL", "IN", "KS", "LA", "MA", "MS", "MO", "NV", "NJ", "NY", "NC", "OH", "TN", "VA", "HI", "ME", "WA", "AZ", "KY", "MD", "WI"],
                vendorErr:0,
                checkrefi: true,
                formatted_address: '',
                isSSNState:false,
                active_el: 1,
                sub_ven:0,
                id: '',
                data: {
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: 'newform@na.com',
                    address_line_1: '',
                    address_line_2: '',
                    city: '',
                    state: '',
                    zip: '',
                    mm: '',
                    dd: '',
                    yyyy: '',
                    dob: '',
                    medicare: '',
                    gender: 'Male',
                    agent: '',
                    sub_agent:'',
                    grupo:'',
                    bin:'',
                    ssn:'',
                    insurer:'',
                    doctor:''
                },
                report: '',
                vendor: '',
                showLoadingPage: false,
                accepted: false,
                lastrefi: '',
                rejected: false,
                error: false,
                cashavail: 0,
                wantcash: 0,
                isAddressValid: true,
                eligiblityStatus: 0,
                showEligiblityStatus: false,
                messageErr: '',
                showError: false,
                errmsg: '',
                addressFull: false,
            }
        },
        computed:{
            
        },
        methods: {
            selectChange(){
                if(this.ssnStates.includes(this.data.state)){
                    this.isSSNState= true;
                }else{
                    this.isSSNState=  false;
                }
            },
            SearchAddressData() {
                this.messageErr= null;
                this.addressFull = false;
                this.showError = false;
                if(this.data.medicare==null && this.data.ssn==null){
                    this.showError = true;
                    this.messageErr = "Medicaid / SSN is required";
                }else{
                    this.showError = false;
                    this.checkDuplicate(this.data.medicare);
                }
            },
            checkDuplicate(medi){
                this.showLoadingPage = true;
                if(medi!=null){
                    window.axios.post("https://arlo.tycoonmach.net/api/checkmedicaid", {
                        medi: this.data.medicare,
                        ssn: this.data.ssn,
                    }).then(response => {
                        if(response.data.status=="duplicate"){
                            this.showLoadingPage = false;
                            this.messageErr = "Duplicate Lead";
                            this.showError = true;
                        }else{
                            this.verifyMediCaid();
                            //this.createLead();
                        }
                    });
                }else{
                    this.verifyMediCaid();
                }
                
            },
            verifyMediCaid(){
                this.showLoadingPage = true;
                if(this.data.mm<10){
                    this.data.mm = "0"+ parseInt(this.data.mm);
                }
                if(this.data.dd<10){
                    this.data.dd = "0"+ parseInt(this.data.dd);
                }

                this.data.dob = this.data.mm + "/" + this.data.dd + "/" + this.data.yyyy;

                let pdata = {
                    first_name: this.data.first_name,
                    last_name: this.data.last_name,
                    dob: this.data.dob,
                    medi: this.data.medicare,
                    ssn: this.data.ssn,
                    state: this.data.state,
                    vendor: this.vendor
                };

                window.axios.post("https://medi.sylo.ai/api/no-post-medicaidsum", pdata).then(resp => {
                    let response = resp.data;
                    if (response.status == "error") {
                        this.messageErr = response.message;
                        this.showError = true;
                        this.showLoadingPage = false;
                    } else {
                        this.data.address_line_1 = response.address_line_1;
                        this.data.address_line_2 = response.address_line_2;
                        this.data.city = response.city;
                        this.data.state = response.state;
                        this.data.zip = response.zip;
                        this.data.gender = response.gender;
                        this.createLead();
                    }
                    
                });

            },
            createLead() {
                this.messageErr = "";
                this.showError = false;
                this.showLoadingPage = true;
                this.data.agent = this.vendor;
                this.data.sub_agent= this.sub_ven;
                this.data.dob = this.data.mm + "/" + this.data.dd + "/" + this.data.yyyy;
                window.axios.post("https://arlo.tycoonmach.net/api/website/people/medicaid", this.data).then(response => {
                    if (response.data.status == 'success') {
                        this.showLoadingPage = false;
                        window.location.reload();
                    } else {
                        this.showLoadingPage = false;
                        this.showError = true;
                        this.messageErr = response.data.message;
                    }
                });
            },
    
            updateLead(pdata) {
                window.axios.post("website/people/update", pdata).then(response => {});
            },
            getAddressData(addressData, placeResultData) {
                console.log(addressData);
                this.formatted_address = placeResultData.formatted_address;
                this.data.city = addressData.locality;
                if (this.data.city == undefined && addressData.administrative_area_level_1 == 'NY') {
                    this.data.city = "New York";
                }
                this.data.address_line_1 = this.formatted_address.split(',')[0];
                if(addressData.country=="Puerto Rico"){
                    this.data.state = "PR";
                }else{
                    this.data.state = addressData.administrative_area_level_1;
                }
                
                this.data.zip = addressData.postal_code;
                console.log(this.data);
            },
    
            findKey(addr, type) {
                let comp = addr.find(item => item.types.indexOf(type) >= 0);
                return comp ? comp.short_name : null;
            },
            isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); } 
        },
        computed: {
            currentDate() {
                const date = new Date().toDateString();
                var dateStrArr = date.split(' ');
                return dateStrArr[1] + " " + dateStrArr[2] + " " + dateStrArr[3];
            }
        },
        created() {
            this.vendor = this.$route.params.vendor;
            if(!this.isNumber(this.vendor)){
                this.vendorErr=1;
            }
    
            this.sub_ven = this.$route.params.sub_vendor;
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('phone')) {
                this.data.phone = urlParams.get('phone')
            }
            if (urlParams.has('first_name')) {
                this.data.first_name = urlParams.get('first_name');
            }
            if (urlParams.has('last_name')) {
                this.data.last_name = urlParams.get('last_name');
            }
    
        }
    }
    </script>
    
    <style>
    .colordiv {
        border: red solid 2px !important;
    }
    </style>
    