<template>
  <div class="app">
    <input type="text" placeholder="Enter street address" class="text-field-15 w-input" @keydown="checkBack($event)" v-model="search"> 
    <div class="results" v-if="toggle">
      <div class="combo-select-dropdown" v-if="makes==1">
				<ul class="select-list-group-list" data-toggle="false"  v-if="makes==1">
				  <li class="select-list-group-list-item" style="cursor:pointer;" v-for="book in newBooks" @click="addAddress($event, book)"><strong>{{addresstext(book)}}</strong></li>
				</ul>
      </div>
      <div class="result" >
        <span @click="selectResult(book)" @mousedown.prevent>
          
        </span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  watch: {
    search(val) {
       
        if(val.length > 2 && val!="null"){
            this.makeSearch();            
        }
     
    },
    newBooks(val){
        
        if(val.length>0){
            this.toggle = true;
        }
    }
  },
  data () {
    return {
      search: '',
      makes:1,
      toggle: false,
      books: [
        {name: 'Liste des livres de Chair de poule'},
        {name: 'Les Cent Meilleurs Romans policiers de tous les temps'},
        {name: 'Liste des livres publiés par Champ libre'}
      ],
      newBooks: []
    }
  },
  methods: {
    checkBack(e){
      if (e.key === "Backspace" || e.key === "Delete") {
          this.makes =1 ;
      }
    },
    addresstext(address){
      if(address.address2!=null){
        return address.address +  address.address2 + ", " + address.city + ", " + address.state2 + " "+ address.szip;
      }else{
        return  address.address + ", " + address.city + ", " + address.state2 + " "+ address.szip;
      }
    },
    addAddress(event, address){
      event.preventDefault();
      this.makes = 0;
      if(address.address2!=null){
        this.search =  address.address +  address.address2 + ", " + address.city + ", " + address.state2 + " "+ address.szip;
      }else{
        this.search =   address.address + ", " + address.city + ", " + address.state2 + " "+ address.szip;
      }
     
      this.$emit("setAddress", address);
      this.newBooks = [];
    },
    makeSearch(){
      if(this.makes==0){
        return false;
      }
        var formData = new FormData();
        formData.append('address', this.search);
        window.axios.post("https://auto.arlo.ai/getAddressnew", formData).then(response => {
                if(response.data=="error"){
                  this.$emit("noAddressFound");
                }else{
                  this.newBooks = [];
                  if(response.data.length==0){
                    this.$emit("noAddressFound");
                  }else{
                    response.data.forEach(element => {
                      this.newBooks.push(element);
                  });
                  }
                  
                }
            }).catch(error => {
              this.$emit("noAddressFound");
            });

        
    },
    selectResult(book) {
      this.search = book.name;
    }
  }
}
</script>
<style>
.results{
  background-color: white;
  position: absolute;
  z-index: 1;
  width:35%
}
li:nth-child(odd) { background: lightblue; }
li:nth-child(even) { background: #80808030; }

li {
  padding: 0.6rem 1rem;
  margin: 0;
}

li {
  position: relative;
  font-size: 12px;
}

ul {
  list-style: none;
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
  z-index: 1;
}

li:hover {
  color: white;
  background-color: grey;
}

li li.current {
  color: white;
  background-color: pink;
}

li li.active {
  color: white;
  background-color: skyblue;
}

.select-list-group {
  position: relative;
}

.select-list-group,
.select-list-group * {
  width: 100%;
}

.select-list-group .select-list-group-search+.select-list-group__toggle:after {
  content: "v";
  font-family: sans-serif;
  position: absolute;
  top: .6rem;
  right: .7rem;
  width: 2rem;
  padding: 0.6rem;
  text-align: center;
}

.select-list-group .select-list-group-search:focus+.select-list-group__toggle:after {
  content: "^";
}

.select-list-group [data-toggle="false"] {
  display: none;
}

.select-list-group [data-toggle="true"] {
  display: inherit;
  box-shadow: 0 3px 7px -2px rgba(0, 0, 0, 0.2);
}

.select-list-group li[data-display="false"] {
  display: none;
}

.select-list-group li[data-display="true"] {
  display: inherit;
}

.select-list-group li[data-highlight="false"] {
  border-left: 0;
}

.select-list-group li[data-highlight="true"] {
  color: white;
  background-color: grey;
}

.select-list-group::after {
  position: absolute;
  right: 0;
  margin-right: -5px;
  top: 25px;
  right: 35px;
  content: "";
  width: 10px;
  height: 10px;
  background: url(https://image.flaticon.com/icons/png/512/25/25756.png);
  background-repeat: no-repeat;
  background-size: 10px 10px;
}


</style>