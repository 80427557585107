<template>
<div>
    <loading v-if="showLoadingPage"></loading>
    <acceptedScreen v-if="accepted"/>
    <rejectedScreen v-if="rejected"/>
    <HeaderComponent />
    <div class="wf-section">
        <div class="form-step-2" style="padding-top:0px;">
            <div class="question-container w-container" style="margin-bottom:25px;">
                <h1 class="question-2" style="margin-bottom:12px;"><strong class="bold-text-3">Please enter <br class="mobile-newline">info below</strong><br></h1>
                <p class="text-block-45"></p>
            </div>
            <div class="container-10 w-container" style="height:90vh;">
                <div class="w-form">
                    <transition-group name="list" tag="div">
                        <div v-if="active_el==1">
                            <form @submit.prevent="SearchAddressData">
                                 <div class="div-block-150">
                                    <label> Have you refinanced in the last 6 months? </label>
                                </div>
                                <div class="div-block-150">
                                    <div class="choice-div-2 yes-no" style="width:48%" v-bind:class="{'color-div': lastrefi=='yes'}" @click="lastrefi='yes'"><strong class="radio-button-label-3">Yes</strong></div>
                                    <div class="choice-div-2 yes-no" style="width:48%" v-bind:class="{'color-div': lastrefi=='no'}" @click="lastrefi='no'"><strong class="radio-button-label-3">No</strong></div>
                                </div>
                                <div class="div-block-150">
                                    <label> Name </label>
                                </div>
                                <div class="div-block-150">
                                    <input type="text" class="text-field-12 w-input" maxlength="256" v-model="data.first_name" data-name="First Name" placeholder="First Name" required>
                                    <input type="text" class="text-field-13 w-input" maxlength="256" v-model="data.last_name" data-name="Last Name" placeholder="Last Name" id="Last-Name" required>
                                </div>
                                <div class="div-block-150">
                                    <label> Address </label>
                                </div>
                                <div class="div-block-82 address">
                                    <googleautocomplete id="somemap" v-on:placechanged="getAddressData" :addressofLead="formatted_address"> </googleautocomplete>
                                    <input type="text" class="text-field apt form w-input" v-model="data.address_line_2" maxlength="256" name="apartment-2" placeholder="Apt #" id="apartment-2">
                                </div>
                                <div class="div-block-150">
                                    <label> Phone </label>
                                </div>
                                <input type="number" v-model="data.phone" class="text-field-15 w-input" maxlength="256" data-name="Email 2" placeholder="Enter your phone number" required>
                                <div class="div-block-150">
                                    <label> Email </label>
                                </div>
                                <input type="email" v-model="data.email" class="text-field-15 w-input" maxlength="256" data-name="Email 2" placeholder="Enter your email address" required>
                                <div class="div-block-150">
                                    <label> Credit Score </label>
                                </div>
                                <input type="number" v-model="data.Estimated_credit_score" class="text-field-15 w-input" max="900" maxlength="3" placeholder="Estimated credit score" required>
                                <div class="div-block-274">
                                    <button type="submit" v-bind:class="{ 'disabled': lastrefi==''}" class="button-22 w-button">Next</button>
                                </div>
                                <div class="" v-if="error" style="color:red;">
                                    <p>Incomplete address. Please enter address and select from dropdown.</p>
                                </div>
                            </form>
                        </div>
                        <div v-if="active_el==2">
                            <form @submit.prevent="createLead">
                                <div class="div-block-150">
                                    <label> Home Value </label>
                                </div>
                                <input type="number" class="text-field-15 w-input" min="100000" maxlength="256" v-model="data.home_worth" placeholder="Home Value" required>
                                <div class="div-block-150">
                                    <label> Owe On Mortgage </label>
                                </div>
                                <input type="text" pattern="^\d*(\.\d{0,2})?$" class="text-field-15 w-input" maxlength="256" v-model="data.owe_on_mortgage" placeholder="Owe On Mortgage" id="Last-Name" required>
                                <div class="div-block-150">
                                    <label> Current Interest Rate </label>
                                </div>
                                <input type="text" class="text-field-15 w-input" pattern="^\d*(\.\d{0,2})?$" maxlength="256" v-model="data.current_interest_rate" placeholder="Current Interest Rate" required>
                                <div class="div-block-150">
                                    <label> Are you a vetran? </label>
                                </div>
                                <div class="div-block-150">
                                    <div class="choice-div-2 yes-no" style="width:48%" v-bind:class="{'color-div': data.Veteran=='yes'}" @click="data.Veteran='yes'"><strong class="radio-button-label-3">Yes</strong></div>
                                    <div class="choice-div-2 yes-no" style="width:48%" v-bind:class="{'color-div': data.Veteran=='no'}" @click="data.Veteran='no'"><strong class="radio-button-label-3">No</strong></div>
                                </div>
                                <br/>
                                <div class="div-block-150" v-if="cashAvailable">
                                    <label> It looks like you have built up ${{cashavail}}  in home equity. Are you interested in exploring turning a portion of that into cash? </label>
                                </div>
                                <div class="div-block-150" v-if="cashAvailable">
                                    <div class="choice-div-2 yes-no" style="width:48%" v-bind:class="{'color-div': data.cashout=='yes'}" @click="data.cashout='yes'"><strong class="radio-button-label-3">Yes</strong></div>
                                    <div class="choice-div-2 yes-no" style="width:48%" v-bind:class="{'color-div': data.cashout=='no'}" @click="data.cashout='no'"><strong class="radio-button-label-3">No</strong></div>
                                </div>
                                 <br/>
                                <div v-if="data.cashout=='yes'">
                                    <div class="div-block-150">
                                    <label> How much cash you want to take out? </label>
                                </div>
                                <input type="text" pattern="^\d*(\.\d{0,2})?$" class="text-field-15 w-input" maxlength="256" v-model="wantcash" placeholder="Cashout Amount" id="Last-Name" required>
                                </div>
                                <div>
                                    <div class="div-block-150">
                                    <label> Enter Jornaya Token (optional) </label>
                                </div>
                                <input type="text" class="text-field-15 w-input" maxlength="256" v-model="journaya" placeholder="Enter Jornaya Token" id="Last-Name">
                                </div>
                                <div class="div-block-274">
                                    <button type="submit" class="button-22 w-button">Submit</button>
                                </div>
                            </form>
                            <div class="div-block-150">
                                <a href="#" style="text-decoration: none;color: black;" @click.prevent="active_el--">&larr;Back</a>
                            </div>

                        </div>
                    </transition-group>
                </div>
            </div>
        </div>
    </div>
</div>
<FooterComponent />
</template>

<script>
import HeaderComponent from '../common/headerComponent.vue'
import FooterComponent from '../common/footerComponent.vue'
import acceptedScreen from '../common/acceptedScreen.vue'
import googleautocomplete from '../common/googleAddressNew.vue'
import loading from '../common/newLoading.vue'
import rejectedScreen from '../common/rejectedScreen.vue'
export default {
    name: 'HelloWorld',
    
    components: {
        HeaderComponent,
        FooterComponent,
        googleautocomplete,
        loading,
        acceptedScreen,
        rejectedScreen
    },
    data() {
        return {
            checkrefi: true,
            formatted_address: '',
            active_el: 1,
            data: {
                first_name: '',
                user_id:1,
                last_name: '',
                phone: '',
                email: '',
                employed: 'yes',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                zip: '',
                lead_type: 'refi',
                user_id: 1,
                refinance_need: '',
                owe_on_mortgage: '',
                current_interest_rate: '',
                cashout: '',
                Budget_Range: '',
                id: 0,
                Estimated_credit_score: '',
                home_equity: '',
                loan_term: 30,
                home_worth: '',
                lvt: '',
                Veteran: 'no',
                source:'',
                journaya:''
            },
            report: '',
            vendor:'',
            showLoadingPage: false,
            accepted: false,
            lastrefi:'',
            rejected : false,
            error:false,
            cashavail:0,
            wantcash:0,
        }
    },
    methods: {
        SearchAddressData() {
            
            this.error= false;
            if(this.data.address_line_1=='' || this.data.zip=='' || this.data.state==''){
                this.error= true;
                return false;
            }
            this.showLoadingPage = true;
            let pdata = {
                address: this.data.address_line_1,
                apt: this.data.address_line_2,
                city: this.data.city,
                state: this.data.state,
                zip: this.data.zip,
            };
            window.axios.post("website/property/report", pdata).then(response => {
                try {
                    this.report = response.data.report;
                    this.data.home_worth = this.report.homevalue;
                    this.data.owe_on_mortgage = this.report.owe_on_mortgage,
                        this.data.current_interest_rate = this.report.interest_rate;
                    this.data.home_equity = this.report.home_equity;
                    this.data.lvt = this.report.ltv;
                    this.data.loan_term = this.report.terms;
                    this.data.Budget_Range = this.report.potential_loan_value;
                } catch (error) {

                }
                this.showLoadingPage = false;
                this.active_el++;
            });
        },
        createLead() {
            this.showLoadingPage = true;
            this.data.home_equity = parseInt(this.data.home_worth) - parseInt(this.data.owe_on_mortgage);
            this.data.Budget_Range = parseInt(this.data.owe_on_mortgage) + parseInt(this.wantcash);
            this.data.source= this.vendor;
            window.axios.post("website/people/callcenter", this.data).then(response => {
                if(response.data.status=='success'){
                    this.saveCredit(response.data.id);
                    this.saveJournaya(response.data.id);
                }else{
                    this.showLoadingPage = false;
                    this.rejected = true;
                }               
            });
        },
        saveJournaya(id){
            if(this.journaya!='' && this.journaya!=null){
               window.axios.post("/website/people/trust", {
                   id: id,
                   journaya: this.journaya
               }).then(response => {              
            }); 
            }
        },
        saveCredit(id){
            window.axios.get("website/people/fcredit/" + id + '/'+this.data.Estimated_credit_score).then(response => {
                if(response.data.status=='success'){
                    this.evaluate(id);
                }                
            });
        },
        evaluate(id){
            let pdata = {
                id: id,
                user_id: 5,
                listing_address : this.lastrefi
            };
            if(this.data.Budget_Range<150000 || this.lastrefi=='yes' || this.data.Estimated_credit_score<640){
                window.axios.post("website/people/update",pdata).then(response => {   
                    this.rejected = true;            
                });
            }else{
                 this.accepted = true;    
            }
            this.showLoadingPage = false;
        },
        getAddressData(addressData, placeResultData) {
            this.formatted_address = placeResultData.formatted_address;
            this.data.city = addressData.locality;
            if (this.data.city == undefined && addressData.administrative_area_level_1 == 'NY') {
                this.data.city = "New York";
            }
            this.data.address_line_1 = this.formatted_address.split(',')[0];
            this.data.state = addressData.administrative_area_level_1;
            this.data.zip = addressData.postal_code;
        },

        findKey(addr, type) {
            let comp = addr.find(item => item.types.indexOf(type) >= 0);
            return comp ? comp.short_name : null;
        },

    },
    computed:{
        cashAvailable(){
            let home_equity = parseInt(this.data.home_worth) - parseInt(this.data.owe_on_mortgage);
            if(this.data.Veteran=='yes'){
                    this.cashavail =  parseInt(home_equity);
            }else{
                    this.cashavail =  parseInt(home_equity *.8);
            }
            if(this.cashavail>0){
                
                return true;
            }else{
                this.data.cashout='no';
                return false;
            }
        }
    },
    created() {
        this.vendor = this.$route.params.vendor;
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('email')) {
            this.data.email = urlParams.get('email')
        }
        if (urlParams.has('phone')) {
            this.data.phone = urlParams.get('phone')
        }

        if (urlParams.has('state')) {
            this.state = urlParams.get('state');
        }

        if (urlParams.has('first_name')) {
            this.data.first_name = urlParams.get('first_name');
        }

        if (urlParams.has('last_name')) {
            this.data.last_name = urlParams.get('last_name');
        }

        if (urlParams.has('address1')) {
            if (urlParams.get('address1') != undefined) {
                this.data.address_line_1 = urlParams.get('address1');
            }
        }
        if (urlParams.has('city')) {
            if (urlParams.get('city') != undefined) {
                this.data.city = urlParams.get('city');
            }
        }
        if (urlParams.has('state')) {
            if (urlParams.get('state') != undefined) {
                this.data.state = urlParams.get('state');
            }
        }
        if (urlParams.has('postal_code')) {
            if (urlParams.get('postal_code') != undefined) {
                this.data.zip = urlParams.get('postal_code');
            }
        }
        if(this.data.address_line_1!=undefined && this.data.address_line_1!='' && this.data.address_line_1!=null){
            this.formatted_address = this.data.address_line_1 + ", " + this.data.city + ", " + this.data.state + " " + this.data.zip;
        }
        

    }
}
</script>
