import { createWebHistory, createRouter } from "vue-router";

import PreSell1 from "@/components/HomePages/singlePageFullForm.vue";
import DebtForm from "@/components/HomePages/SingleDebtForm.vue";
import PersonalForm from "@/components/HomePages/singlePersonalLoan.vue";
import medicare3 from "@/components/HomePages/medicalid.vue";
import NotFound from "@/components/HomePages/NotFound.vue";
import medicaidverified from "@/components/HomePages/medicaidverified.vue";

import medicalidback from "@/components/HomePages/medicalidback.vue";
import completeform from "@/components/HomePages/completeform.vue";
import updateAddress from "@/components/HomePages/updateAddress.vue";
const routes = [
  { 
    path: '/:vendor',
    name: 'PreSell1',
    component: PreSell1,
  },
  { 
    path: '/debt/:vendor',
    name: 'DebtForm',
    component: DebtForm,
  },
  { 
    path: '/personal/:vendor',
    name: 'PersonalForm',
    component: PersonalForm,
  },
  { 
    path: '/medicaid/:vendor/:sub_vendor?',
    name: 'medicare3',
    component: medicalidback,
  },
  { 
    path: '/medicaid2/:vendor/:sub_vendor?',
    name: 'medicaidverified',
    component: medicaidverified,
  },
  { 
    path: '/update-address/:id',
    name: 'updateAddress',
    component: updateAddress,
  },
  { 
    path: '/complete-form/:id',
    name: 'completeform',
    component: completeform,
  },
  { 
    path: '/medicaid3/:vendor/:sub_vendor?',
    name: 'medicalidback',
    component: medicalidback,
  },
 {
    path:  "/:catchAll(.*)",
    name: "404",
    component: NotFound,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;