<template>
    <div>
        <div class="wf-section" style="height:1080px !important;">
            <loading v-if="showLoadingPage"></loading>
            <acceptedScreen v-if="showAccepted"></acceptedScreen>
            <div class="form-step-2 formimg" style="padding-top:0px;">
                <div class="question-container w-container" style="margin-bottom:25px;">
                    <h1 class="question-2" style="margin-bottom:12px;"><strong class="bold-text-3">Please enter <br
                                class="mobile-newline">info below</strong><br></h1>
                </div>
                <div class="container-10 w-container" style="height:90vh;">
                    <div class="w-form">
                        
                                <div >
                                    <form @submit.prevent="SearchAddressData">


                                        <div>
                                            <div class="div-block-150">
                                                <label> Select Address Type </label>
                                            </div>
                                            <div class="div-block-150">
                                                <select class="text-field-12 w-input" v-model="data.address_type" required>
                                                    <option value="" selected disabled> Select Address Type</option>
                                                    <option value="1"> Home Address </option>
                                                    <option value="2"> HC Box </option>
                                                    <option value="3"> PO Box </option>
                                                    <option value="4"> RR Box </option>
                                                </select>

                                                <input v-if="data.address_type == 3" type="number"
                                                    class="text-field-12 w-input" maxlength="256" v-model="data.box"
                                                    data-name="Addres" placeholder="PO Box #" required>
                                            </div>
                                            <div class="div-block-150" v-if="data.address_type == 2">

                                                <input type="number" class="text-field-13 w-input" maxlength="256"
                                                    v-model="data.hc" data-name="Last Name" placeholder="HC # "
                                                    id="House Number" required>
                                                <input type="text" class="text-field-12 w-input" maxlength="256"
                                                    v-model="data.box" data-name="Addres" placeholder="Box #" required>
                                            </div>

                                            <div class="div-block-150" v-if="data.address_type == 4">

                                                <input type="number" class="text-field-13 w-input" maxlength="256"
                                                    v-model="data.hc" data-name="Last Name" placeholder="RR # "
                                                    id="House Number" required>
                                                <input type="text" class="text-field-12 w-input" maxlength="256"
                                                    v-model="data.box" data-name="Addres" placeholder="Box #" required>
                                            </div>
                                            <div class="div-block-150" v-if="data.address_type == 1">

                                                <input type="text" class="text-field-13 w-input" maxlength="256"
                                                    v-model="data.house_no" data-name="Last Name" placeholder="House Number"
                                                    id="House Number" required>
                                                <input type="text" class="text-field-13 w-input" maxlength="256"
                                                    v-model="data.street_name" data-name="Last Name"
                                                    placeholder="Street Name" id="Street Name" required>
                                            </div>
                                            <div class="div-block-150" v-if="data.address_type == 1">
                                                <input type="text" class="text-field-12 w-input" maxlength="256"
                                                    v-model="data.address_line_2" data-name="Addres" placeholder="APT #">
                                                <select class="text-field-13 w-input" maxlength="256" v-model="data.city"
                                                    data-name="Last Name" placeholder="City" id="Last-Name" required>
                                                    <option value="Adjuntas">Adjuntas</option>
                                                    <option value="Aguada">Aguada</option>
                                                    <option value="Aguadilla">Aguadilla</option>
                                                    <option value="Aguas Buenas">Aguas Buenas</option>
                                                    <option value="Aibonito">Aibonito</option>
                                                    <option value="Anasco">Anasco</option>
                                                    <option value="Arecibo">Arecibo</option>
                                                    <option value="Arroyo">Arroyo</option>
                                                    <option value="Barceloneta">Barceloneta</option>
                                                    <option value="Barranquitas">Barranquitas</option>
                                                    <option value="Bayamon">Bayamon</option>
                                                    <option value="Cabo Rojo">Cabo Rojo</option>
                                                    <option value="Caguas">Caguas</option>
                                                    <option value="Camuy">Camuy</option>
                                                    <option value="Canovanas">Canovanas</option>
                                                    <option value="Carolina">Carolina</option>
                                                    <option value="Catano">Catano</option>
                                                    <option value="Cayey">Cayey</option>
                                                    <option value="Ceiba">Ceiba</option>
                                                    <option value="Ciales">Ciales</option>
                                                    <option value="Cidra">Cidra</option>
                                                    <option value="Coamo">Coamo</option>
                                                    <option value="Comerio">Comerio</option>
                                                    <option value="Corozal">Corozal</option>
                                                    <option value="Culebra">Culebra</option>
                                                    <option value="Dorado">Dorado</option>
                                                    <option value="Fajardo">Fajardo</option>
                                                    <option value="Florida">Florida</option>
                                                    <option value="Guanica">Guanica</option>
                                                    <option value="Guayama">Guayama</option>
                                                    <option value="Guayanilla">Guayanilla</option>
                                                    <option value="Guaynabo">Guaynabo</option>
                                                    <option value="Gurabo">Gurabo</option>
                                                    <option value="Hatillo">Hatillo</option>
                                                    <option value="Hormigueros">Hormigueros</option>
                                                    <option value="Humacao">Humacao</option>
                                                    <option value="Isabela">Isabela</option>
                                                    <option value="Jayuya">Jayuya</option>
                                                    <option value="Juana Diaz">Juana Diaz</option>
                                                    <option value="Juncos">Juncos</option>
                                                    <option value="Lajas">Lajas</option>
                                                    <option value="Lares">Lares</option>
                                                    <option value="Las Marias">Las Marias</option>
                                                    <option value="Las Piedras">Las Piedras</option>
                                                    <option value="Loiza">Loiza</option>
                                                    <option value="Luquillo">Luquillo</option>
                                                    <option value="Manati">Manati</option>
                                                    <option value="Maricao">Maricao</option>
                                                    <option value="Maunabo">Maunabo</option>
                                                    <option value="Mayaguez">Mayaguez</option>
                                                    <option value="Moca">Moca</option>
                                                    <option value="Morovis">Morovis</option>
                                                    <option value="Naguabo">Naguabo</option>
                                                    <option value="Naranjito">Naranjito</option>
                                                    <option value="Orocovis">Orocovis</option>
                                                    <option value="Patillas">Patillas</option>
                                                    <option value="Penuelas">Penuelas</option>
                                                    <option value="Ponce">Ponce</option>
                                                    <option value="Quebradillas">Quebradillas</option>
                                                    <option value="Rincon">Rincon</option>
                                                    <option value="Rio Grande">Rio Grande</option>
                                                    <option value="Sabana Grande">Sabana Grande</option>
                                                    <option value="Salinas">Salinas</option>
                                                    <option value="San German">San German</option>
                                                    <option value="San Juan">San Juan</option>
                                                    <option value="San Lorenzo">San Lorenzo</option>
                                                    <option value="San Sebastian">San Sebastian</option>
                                                    <option value="Santa Isabel">Santa Isabel</option>
                                                    <option value="Toa Alta">Toa Alta</option>
                                                    <option value="Toa Baja">Toa Baja</option>
                                                    <option value="Trujillo Alto">Trujillo Alto</option>
                                                    <option value="Utuado">Utuado</option>
                                                    <option value="Vega Alta">Vega Alta</option>
                                                    <option value="Vega Baja">Vega Baja</option>
                                                    <option value="Vieques">Vieques</option>
                                                    <option value="Villalba">Villalba</option>
                                                    <option value="Yabucoa">Yabucoa</option>
                                                    <option value="Yauco">Yauco</option>
                                                </select>
                                            </div>
                                            <select class="text-field-13 w-input" maxlength="256"
                                                v-if="data.address_type >= 2" v-model="data.city" data-name="Last Name"
                                                placeholder="City" list="cities" id="Last-Name" required>
                                                <option value="Adjuntas">Adjuntas</option>
                                                <option value="Aguada">Aguada</option>
                                                <option value="Aguadilla">Aguadilla</option>
                                                <option value="Aguas Buenas">Aguas Buenas</option>
                                                <option value="Aibonito">Aibonito</option>
                                                <option value="Anasco">Anasco</option>
                                                <option value="Arecibo">Arecibo</option>
                                                <option value="Arroyo">Arroyo</option>
                                                <option value="Barceloneta">Barceloneta</option>
                                                <option value="Barranquitas">Barranquitas</option>
                                                <option value="Bayamon">Bayamon</option>
                                                <option value="Cabo Rojo">Cabo Rojo</option>
                                                <option value="Caguas">Caguas</option>
                                                <option value="Camuy">Camuy</option>
                                                <option value="Canovanas">Canovanas</option>
                                                <option value="Carolina">Carolina</option>
                                                <option value="Catano">Catano</option>
                                                <option value="Cayey">Cayey</option>
                                                <option value="Ceiba">Ceiba</option>
                                                <option value="Ciales">Ciales</option>
                                                <option value="Cidra">Cidra</option>
                                                <option value="Coamo">Coamo</option>
                                                <option value="Comerio">Comerio</option>
                                                <option value="Corozal">Corozal</option>
                                                <option value="Culebra">Culebra</option>
                                                <option value="Dorado">Dorado</option>
                                                <option value="Fajardo">Fajardo</option>
                                                <option value="Florida">Florida</option>
                                                <option value="Guanica">Guanica</option>
                                                <option value="Guayama">Guayama</option>
                                                <option value="Guayanilla">Guayanilla</option>
                                                <option value="Guaynabo">Guaynabo</option>
                                                <option value="Gurabo">Gurabo</option>
                                                <option value="Hatillo">Hatillo</option>
                                                <option value="Hormigueros">Hormigueros</option>
                                                <option value="Humacao">Humacao</option>
                                                <option value="Isabela">Isabela</option>
                                                <option value="Jayuya">Jayuya</option>
                                                <option value="Juana Diaz">Juana Diaz</option>
                                                <option value="Juncos">Juncos</option>
                                                <option value="Lajas">Lajas</option>
                                                <option value="Lares">Lares</option>
                                                <option value="Las Marias">Las Marias</option>
                                                <option value="Las Piedras">Las Piedras</option>
                                                <option value="Loiza">Loiza</option>
                                                <option value="Luquillo">Luquillo</option>
                                                <option value="Manati">Manati</option>
                                                <option value="Maricao">Maricao</option>
                                                <option value="Maunabo">Maunabo</option>
                                                <option value="Mayaguez">Mayaguez</option>
                                                <option value="Moca">Moca</option>
                                                <option value="Morovis">Morovis</option>
                                                <option value="Naguabo">Naguabo</option>
                                                <option value="Naranjito">Naranjito</option>
                                                <option value="Orocovis">Orocovis</option>
                                                <option value="Patillas">Patillas</option>
                                                <option value="Penuelas">Penuelas</option>
                                                <option value="Ponce">Ponce</option>
                                                <option value="Quebradillas">Quebradillas</option>
                                                <option value="Rincon">Rincon</option>
                                                <option value="Rio Grande">Rio Grande</option>
                                                <option value="Sabana Grande">Sabana Grande</option>
                                                <option value="Salinas">Salinas</option>
                                                <option value="San German">San German</option>
                                                <option value="San Juan">San Juan</option>
                                                <option value="San Lorenzo">San Lorenzo</option>
                                                <option value="San Sebastian">San Sebastian</option>
                                                <option value="Santa Isabel">Santa Isabel</option>
                                                <option value="Toa Alta">Toa Alta</option>
                                                <option value="Toa Baja">Toa Baja</option>
                                                <option value="Trujillo Alto">Trujillo Alto</option>
                                                <option value="Utuado">Utuado</option>
                                                <option value="Vega Alta">Vega Alta</option>
                                                <option value="Vega Baja">Vega Baja</option>
                                                <option value="Vieques">Vieques</option>
                                                <option value="Villalba">Villalba</option>
                                                <option value="Yabucoa">Yabucoa</option>
                                                <option value="Yauco">Yauco</option>
                                            </select>
                                            <div class="div-block-150" v-if="data.address_type >= 1">
                                                <input type="text" class="text-field-12 w-input" maxlength="2"
                                                    v-model="data.state" data-name="State" placeholder="State" required>
                                                <input type="text" class="text-field-13 w-input" maxlength="5"
                                                    v-model="data.zip" data-name="Last Name" placeholder="Zip"
                                                    id="Last-Name" required>
                                            </div>
                                        </div>

                                        <div class="div-block-274">
                                            <button type="submit" class="button-22 w-button">Submit</button>
                                        </div>
                                        <div class="" v-if="address_val" style="color:red;">
                                            <p>{{ errorAddressDescription }}</p>
                                        </div>
                                    </form>
                                </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="height:450px">
    </div>
    <FooterComponent />
</template>

<script>
import HeaderComponent from '../common/headerComponent.vue'
import FooterComponent from '../common/footerComponent.vue'
import acceptedScreen from '../common/acceptedScreen.vue'
import googleautocomplete from '../common/googleAddressNew.vue'
import loading from '../common/newLoading.vue'
import rejectedScreen from '../common/rejectedScreen.vue'
import Multiselect from 'vue-multiselect'
import autocomplete from './autocomplete.vue'
import errorDialog from './errorDialog.vue'
import doc from './doc.json'
import docAutocomplete from './docAutocomplete.vue';
export default {
    name: 'HelloWorld',

    components: {
        HeaderComponent,
        FooterComponent,
        googleautocomplete,
        loading,
        acceptedScreen,
        rejectedScreen,
        Multiselect,
        autocomplete,
        docAutocomplete,
        errorDialog
    },
    data() {
        return {
            showBlk: false,
            vendorErr: 0,
            id: 0,
            drfirst_name: '',
            drlast_name: '',
            errorAddressDescription: '',
            checkrefi: true,
            value: '',
            formatted_address: '',
            active_el: 1,
            showErrorDialog: false,
            sub_ven: 0,
            options: doc,
            dr_first: "",
            dr_first_last: "",
            dr_last: "",
            id: '',
            showError: false,
            allDoc: [],
            address_val: false,
            address_try: 0,
            data: {
                id:'',
                hc: '',
                box: '',
                urban: '',
                house_no: '',
                street_no: '',
                street_name: '',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: 'PR',
                zip: '',
                address_valid: 0
            },
            showAccepted: false,
            report: '',
            docAuto: false,
            vendor: '',
            showLoadingPage: false,
            accepted: false,
            lastrefi: '',
            rejected: false,
            error: false,
            cashavail: 0,
            wantcash: 0,
            isAddressValid: true,
            eligiblityStatus: 0,
            showEligiblityStatus: false,
            messageErr: '',
            showError: false,
            errmsg: '',
            addressFull: false,
            npi: 0,
            found: false,
            errmessage: [],
        }
    },
    watch: {
        value: function (newVal, oldVal) {
            this.checkDoc(newVal);
        },
    },
    methods: {
        noAddressFound() {
            this.showBlk = true;
            this.data.address_line_1 = "";
            this.data.address_line_2 = "";
        },
        showBlock() {
            this.showBlk = true;
            this.data.address_line_1 = "";
            this.data.address_line_2 = "";
        },
        nameWithLang({
            id,
            name_with_city
        }) {
            if (name_with_city == "Others") {
                return name_with_city;
            }
            return "Dr. " + name_with_city;
        },
        setAddress(address) {
            this.data.address_line_1 = address.address;
            this.data.address_line_2 = address.address2;
            this.data.city = address.city;
            this.data.state = address.state2;
            this.data.zip = address.szip;
        },
        SearchAddressData() {
            this.messageErr = null;
            this.addressFull = false;
            this.showError = false;
            this.showLoadingPage = true;
            if (this.data.address_type == 1) {
                this.data.address_line_1 = this.data.house_no + " " + this.data.street_name;
            } else if (this.data.address_type == 2) {
                this.data.address_line_1 = "HC " + this.data.hc + " Box " + this.data.box;
            } else if (this.data.address_type == 4) {
                this.data.address_line_1 = "RR " + this.data.hc + " Box " + this.data.box;
            } else if (this.data.address_type == 3) {
                this.data.address_line_1 = "PO Box  " + this.data.box;
            }
            this.validateAddress();
        },

        validateAddress() {
            this.address_try++;
            if (this.address_try > 10) {
                this.createLead();
            } else {
                this.errorAddressDescription = "";
                window.axios.post("https://arlo.tycoonmach.net/api/validate-address", this.data).then(response => {
                    try {
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(response.data, 'text/xml');
                        const errorElement = xmlDoc.querySelector('Address Error');
                        if (errorElement !== null) {
                            this.errorAddressDescription = "Address Error: " + errorElement.querySelector('Description').textContent;
                            this.address_val = true;
                            this.showLoadingPage = false;
                        } else {
                            this.data.address_line_1 = xmlDoc.querySelector('Address2').textContent;
                            this.data.city = xmlDoc.querySelector('City').textContent;
                            this.data.zip = xmlDoc.querySelector('Zip5').textContent;
                            this.data.address_valid = 1;
                            this.createLead();
                        }
                    } catch (error) {
                        this.createLead();
                    }
                });
            }
        },
        createLead() {
            this.messageErr = "";
            this.showError = false;
            this.showLoadingPage = true;
            this.data.agent = this.vendor;
            this.data.sub_agent = this.sub_ven;
            window.axios.post("https://arlo.tycoonmach.net/api/website/people/address", this.data).then(response => {
                if (response.data.status == 'success') {
                    this.showLoadingPage = false;
                    this.showAccepted= true;
                } else {
                    this.showLoadingPage = false;
                    this.showError = true;
                    this.messageErr = response.data.message;
                }
            });
        },

    },
    
    created() {
        this.data.id = this.$route.params.id;
    }
}
</script>

<style>
.colordiv {
    border: red solid 2px !important;
}

.imageblock {
    padding-left: 20px;
    width: 50%;
}

.formblock {
    width: 65%;
}

.w-form.formwithimag {
    display: flex;
    align-items: center;
    width: 100%;
}

.formimg .container-10 {
    margin-bottom: 20px;
    padding-right: 0%;
    padding-left: 0%;
}
</style>
