<template>

<div class="wf-section footer">
    <div class="div-block-79">    
      <div class="div-block-75">
          <div class="text-block-23">Copyright {{year}} <br/><br/>All Rights Reserved</div>
        </div>
      </div>
    </div>
</template>
<script>
  export default {
    computed:{
      israteGenie(){
        var baseUrl = window.location.origin;
        if(baseUrl.includes('rategenie')){
          return "The RateGenie";
        }else{
          return "MortgageOrganic.com";
        }
      },
      checkCompany(){
        var baseUrl = window.location.origin;
        if(baseUrl.includes('rategenie')){
          return true;
        }else{
          return false;
        }
      },
      year(){
        return new Date().getFullYear();
      }
    }
  }
</script>