<template>
    <div>
        <loading v-if="showLoadingPage"></loading>
        <acceptedScreen v-if="accepted" />
        <rejectedScreen v-if="rejected" />
        <HeaderComponent />
        <errorDialog :message="errmessage" :id="id" v-if="showErrorDialog"></errorDialog>
        <docAutocomplete v-if="docAuto" :doctorDetails="allDoc" @setDoctor="setDoctor" @closeDialog="docAuto=false;"/>
        <div class="wf-section" style="height:1080px !important;">

            <div class="form-step-2 formimg" style="padding-top:0px;">
                <div class="question-container w-container" style="margin-bottom:25px;">
                    <h1 class="question-2" style="margin-bottom:12px;"><strong class="bold-text-3">Please enter <br
                                class="mobile-newline">info below</strong><br></h1>
                    <p class="text-block-45" style="color:red;font-size:16px;" v-if="vendorErr == 1">Your form link doesn't
                        look correct. Please use the link on your dashboard. <a href="https://dashboard.leadtrack.app/login"
                            target="_blank">Click here</a></p>
                </div>
                <div class="container-10 w-container" style="height:90vh;">
                    <div class="w-form formwithimag">
                        <div class="formblock">
                            <transition-group name="list" tag="div">
                                <div v-if="active_el == 1">
                                    <form @submit.prevent="SearchAddressData">
                                        
                                        <div class="div-block-150">
                                            <label> Select Insurer </label>
                                        </div>
                                        <div class="div-block-150">
                                            <select v-model="data.insurer" class="text-field-43 w-input" id="work-Medicaid"
                                                required>
                                                <option value="" disabled>Select Insurer</option>
                                                <option value="MMM">MMM</option>
                                                <option value="Plan De Salud Menonita">Plan De Salud Menonita</option>
                                                <option value="Triple S">Triple S</option>
                                                <option value="First Medical">First Medical</option>
                                                <option value="MSC">MCS</option>
                                            </select>
                                        </div>
                                        <div class="div-block-150">
                                            <label> Enter your insurance ID: (Should start with 008 and 13 digits
                                                long)</label>
                                        </div>
                                        <input type="text" v-model="data.medicare" class="text-field-15 w-input"
                                            placeholder="Enter your insurance ID" maxlength="13" required>


                                        <div class="div-block-150">
                                            <label> Who is your primary doctor? (Listed under PCP on Vital Card)</label>
                                        </div>

                                        <multiselect v-model="value" :options="options" :custom-label="nameWithLang"
                                            placeholder="Select Doctor" label="name" track-by="name" required></multiselect>
                                        <div class="div-block-150" style="margin-top: 15px;" v-if="value.id == 0">
                                            <br />
                                            <input type="text" class="text-field-12 w-input" maxlength="256"
                                                v-model="dr_first" data-name="" placeholder="First Name" required>
                                            <input type="text" class="text-field-12 w-input" maxlength="256"
                                                v-model="dr_first_last" data-name="" placeholder="First Last Name">
                                            <input type="text" class="text-field-13 w-input" maxlength="256"
                                                v-model="dr_last" data-name="" placeholder="Second Last Name" id="Last-Name"
                                                required>
                                        </div>
                                        <div class="div-block-150" style="margin-top: 15px;" v-if="value.id == 0">
                                            <br />
                                            <a href="#" @click="SearchDoctor($event)">Search</a>

                                        </div>
                                        <div class="div-block-150" style="margin-top: 15px;" v-if="value.id == 0">
                                            <br />
                                            <span v-if="showError" style="color:red">No Doctor found with that name.</span>
                                        </div>
                                        
                                        <div class="div-block-274">
                                            <button type="submit" class="button-22 w-button">Submit</button>
                                        </div>
                                        <div class="" v-if="showError" style="color:red;">
                                            <p>{{ messageErr }}</p>
                                        </div>
                                    </form>
                                </div>

                            </transition-group>
                        </div>
                        <div class="imageblock">
                            <span v-if="data.insurer != ''"> Sample Card </span>
                            <img src="@/assets/cards/medcard_mmm.jpg" v-if="data.insurer == 'MMM'" />
                            <img src="@/assets/cards/medcard_menonita.jpg"
                                v-if="data.insurer == 'Plan De Salud Menonita'" />
                            <img src="@/assets/cards/medcard_sss.jpg" v-if="data.insurer == 'Triple S'" />
                            <img src="@/assets/cards/medcard_first.jpg" v-if="data.insurer == 'First Medical'" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="height:450px">
    </div>
    <FooterComponent />
</template>

<script>
import HeaderComponent from '../common/headerComponent.vue'
import FooterComponent from '../common/footerComponent.vue'
import acceptedScreen from '../common/acceptedScreen.vue'
import googleautocomplete from '../common/googleAddressNew.vue'
import loading from '../common/newLoading.vue'
import rejectedScreen from '../common/rejectedScreen.vue'
import Multiselect from 'vue-multiselect'
import autocomplete from './autocomplete.vue'
import errorDialog from './errorDialog.vue'
import doc from './doc.json'
import docAutocomplete from './docAutocomplete.vue';
export default {
    name: 'HelloWorld',

    components: {
        HeaderComponent,
        FooterComponent,
        googleautocomplete,
        loading,
        acceptedScreen,
        rejectedScreen,
        Multiselect,
        autocomplete,
        docAutocomplete,
        errorDialog
    },
    data() {
        return {
            showBlk: false,
            vendorErr: 0,
            id: 0,
            drfirst_name: '',
            drlast_name: '',
            checkrefi: true,
            value: '',
            formatted_address: '',
            active_el: 1,
            showErrorDialog: false,
            sub_ven: 0,
            options: doc,
            dr_first: "",
            dr_first_last: "",
            dr_last: "",
            id: '',
            showError: false,
            allDoc: [],
            data: {

                medicare: '',
                gender: 'Male',
                agent: '',
                sub_agent: '',
                grupo: '',
                bin: '',
                insurer: '',
                doctor: ''
            },
            report: '',
            docAuto: false,
            vendor: '',
            showLoadingPage: false,
            accepted: false,
            lastrefi: '',
            rejected: false,
            error: false,
            cashavail: 0,
            wantcash: 0,
            isAddressValid: true,
            eligiblityStatus: 0,
            showEligiblityStatus: false,
            messageErr: '',
            showError: false,
            errmsg: '',
            addressFull: false,
            npi: 0,
            found: false,
            errmessage:[],
        }
    },
    watch: {
        value: function (newVal, oldVal) {
            this.checkDoc(newVal);
        },
    },
    methods: {
        noAddressFound() {
            this.showBlk = true;
            this.data.address_line_1 = "";
            this.data.address_line_2 = "";
        },
        showBlock() {
            this.showBlk = true;
            this.data.address_line_1 = "";
            this.data.address_line_2 = "";
        },
        nameWithLang({
            id,
            name_with_city
        }) {
            if (name_with_city == "Others") {
                return name_with_city;
            }
            return "Dr. " + name_with_city;
        },
        setAddress(address) {
            this.data.address_line_1 = address.address;
            this.data.address_line_2 = address.address2;
            this.data.city = address.city;
            this.data.state = address.state2;
            this.data.zip = address.szip;
        },
        SearchAddressData() {
            this.messageErr = null;
            this.addressFull = false;
            this.showError = false;
            if (this.data.medicare.substring(0, 3) != "008") {
                this.showError = true;
                this.messageErr = "Incorrect Medicaid. Medicaid Id should start with 008.";
                return false;
            }
            if (this.value == "") {
                this.showError = true;
                this.messageErr = "Doctor is required.";
                return false;
            }
            this.createLead();
        },
        
        setDoctor(doc) {
            this.data.doctor = doc.npi + ":" + doc.first_name + "|" + doc.last_name + ":" + doc.lisc + ":" + doc.phone;
            this.npi = doc.npi;
            this.docAuto = false;
        },
        checkDoc(newVal) {
            if (newVal.id != 0) {
                this.data.doctor = newVal.id + ":" + newVal.first_name + "|" + newVal.last_name + ":" + newVal.lisence + ":" + newVal.phone;
                this.npi = newVal.id;
            } else {
                this.npi = 0;
            }
        },
        SearchDoctor(event) {
            event.preventDefault();
            this.showError = false;
            this.docAuto = false;
            this.showLoadingPage = true;
            this.allDoc=[];
            let url = "https://cdn.tycoonmach.net/api/doc_search";
            let last_name = this.dr_first_last + " " + this.dr_last;
            last_name = last_name.trim();
            const descTex = ["Internal Medicine", "Family Medicine", "General Practice"];
            let data = { "firstName": this.dr_first, "lastName": last_name }
            let vm = this;
            window.axios.post(url, data).then(resp => {
                if (resp.data.length > 0) {
                    let response = resp.data;
                    response.forEach(element => {
                        if (descTex.includes(element.primaryTaxonomy.desc)) {
                            vm.allDoc.push({
                                "npi": element.number,
                                "first_name": element.basic.firstName,
                                "last_name": element.basic.lastName,
                                "middle_name": element.basic.middleName,
                                "phone": element.primaryAddress.teleNumber,
                                "lisc": element.primaryTaxonomy.license,
                                "city": element.primaryAddress.city,
                            })
                        }

                    });
                    if (vm.allDoc.length > 0) {
                        this.docAuto = true;
                        console.log(vm.allDoc);
                    } else {
                        this.showError = true;
                    }
                } else {
                    this.showError = true;
                }
                this.showLoadingPage = false;
            });
        },

        createLead() {

            if (this.npi == 0) {
                this.messageErr = "A vaid doctor name is required.";
                this.showError = true;
                this.showLoadingPage = false;
                return false;
            }
            this.messageErr = "";
            this.showError = false;
            this.showLoadingPage = true;
            this.data.sub_agent = "call";
            this.data.id= this.id;
            this.data.ip_addeess= "null";
            window.axios.post("https://arlo.tycoonmach.net/api/website/people/freecovid2/fb", this.data).then(response => {
                if (response.data.status == 'success') {
                    this.id = response.data.people_id;
                    this.placeOrder();
                } else {
                    this.showLoadingPage = false;
                    this.showError = true;
                    this.messageErr = response.data.message;
                }
            });
        },

        placeOrder() {
            let url = "https://cdn.tycoonmach.net/api/priciption/" + this.id;
            let vm = this;
            window.axios.get(url).then(response => {
                setInterval(function() {
                    vm.checkStatus()
                    }, 60 * 1000);
            });
        },
        getResponseOfForm() {
            let url = 'https://apply.freerapidcovidkits.com/api/website/getOrderStatus/' + this.id;
            window.axios.get(url).then(response => {
                if (response.data.status == "success") {
                    if (response.data.errors != null) {
                        let errors = response.data.errors.status;
                        this.found = true;
                        if (errors == "Unbilled") {
                            let dobError= ["09", "0255", "832", "0833", "2807", "7D"];
                            let docError= ["25", "71", "826", "0205", "0206", "0209", "0204", "0237", "1801", "0207", "1026", "71", "72", "35"];
                            let medError = ["07", "51", "52", "69", "68"];
                            let temp = response.data.errors.extra_details.split(",");
                            let errors = temp;
                            errors.forEach((element) => {
                                if (docError.includes(element.trim())) {
                                    this.errmessage.push("Doctor is incorrect.");
                                }
                                if (medError.includes(element.trim())) {
                                    this.errmessage.push("Medicaid Id is incorrect");
                                }
                                if (dobError.includes(element.trim())) {
                                    this.errmessage.push("Date of birth is incorrect.");
                                }
                                });
                            this.showErrorDialog= true;
                            this.showLoadingPage = false;
                        } else if (errors == "Billed") {
                            window.location.reload();
                        }
                    }
                }
            });
        },
        checkStatus() {
            let k = this;
            if (!this.found) {
                this.getResponseOfForm();
            }
        },

        updateLead(pdata) {
            window.axios.post("website/people/update", pdata).then(response => { });
        },
        getAddressData(addressData, placeResultData) {
            console.log(addressData);
            this.formatted_address = placeResultData.formatted_address;
            this.data.city = addressData.locality;
            if (this.data.city == undefined && addressData.administrative_area_level_1 == 'NY') {
                this.data.city = "New York";
            }
            this.data.address_line_1 = this.formatted_address.split(',')[0];
            if (addressData.country == "Puerto Rico") {
                this.data.state = "PR";
            } else {
                this.data.state = addressData.administrative_area_level_1;
            }

            this.data.zip = addressData.postal_code;
            console.log(this.data);
        },

        findKey(addr, type) {
            let comp = addr.find(item => item.types.indexOf(type) >= 0);
            return comp ? comp.short_name : null;
        },
        isNumber(n) {
            return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
        }
    },
    computed: {
        currentDate() {
            const date = new Date().toDateString();
            var dateStrArr = date.split(' ');
            return dateStrArr[1] + " " + dateStrArr[2] + " " + dateStrArr[3];
        }
    },
    created() {
        this.id = this.$route.params.id;
    }
}
</script>

<style>
.colordiv {
    border: red solid 2px !important;
}

.imageblock {
    padding-left: 20px;
    width: 50%;
}

.formblock {
    width: 65%;
}

.w-form.formwithimag {
    display: flex;
    align-items: center;
    width: 100%;
}

.formimg .container-10 {
    margin-bottom: 20px;
    padding-right: 0%;
    padding-left: 0%;
}
</style>
