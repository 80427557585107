<template>
    <div>
        <loading v-if="showLoadingPage"></loading>
        <acceptedScreen v-if="accepted" />
        <rejectedScreen v-if="rejected" />
        <HeaderComponent />
        <div class="wf-section" style="height:1080px !important;">
            
            <div class="form-step-2" style="padding-top:0px;">
                <div class="question-container w-container" style="margin-bottom:25px;">
                    <h1 class="question-2" style="margin-bottom:12px;"><strong class="bold-text-3">Please enter <br
                                class="mobile-newline">info below</strong><br></h1>
                    <p class="text-block-45" style="color:red;font-size:16px;" v-if="vendorErr == 1">Your form link doesn't
                        look correct. Please use the link on your dashboard. <a href="https://dashboard.leadtrack.app/login"
                            target="_blank">Click here</a></p>
                </div>
                <div class="container-10 w-container" style="height:90vh;">
                    <div class="w-form">
                        <transition-group name="list" tag="div">
                            <div v-if="active_el == 1">
                                <form @submit.prevent="SearchAddressData">
                                    <div class="div-block-150">
                                        <label> Name </label>
                                    </div>
                                    <div class="div-block-150">
                                        <input type="text" class="text-field-12 w-input" maxlength="256"
                                            v-model="data.first_name" data-name="First Name" placeholder="First Name"
                                            required>
                                        <input type="text" class="text-field-13 w-input" maxlength="256"
                                            v-model="data.last_name" data-name="Last Name" placeholder="Last Name"
                                            id="Last-Name" required>
                                    </div>
                                    <div class="div-block-150">
                                        <label> Phone </label>
                                    </div>
                                    <input type="number" v-model="data.phone" class="text-field-15 w-input"
                                        placeholder="Enter Phone" required>
                                    
                                    <div class="div-block-150" >
                                        <label> Enter Full Address </label>
                                    </div>
                                    <div class="div-block-150" >
                                        <input type="text" class="text-field-12 w-input" maxlength="256"
                                            v-model="data.address_line_1" data-name="Addres" placeholder="Address 1"
                                            required>
                                        <input type="text" class="text-field-13 w-input" maxlength="256" v-model="data.city"
                                            data-name="Last Name" placeholder="City" id="Last-Name" required>
                                    </div>
                                    <div class="div-block-150" >
                                        <input type="text" class="text-field-12 w-input" maxlength="2" v-model="data.state"
                                            data-name="State" placeholder="State" required>
                                        <input type="text" class="text-field-13 w-input" maxlength="5" v-model="data.zip"
                                            data-name="Last Name" placeholder="Zip" id="Last-Name" required>
                                    </div>
                                    <div class="div-block-150">
                                        <label> Select Insurer </label>
                                    </div>
                                    <div class="div-block-150">
                                        <select v-model="data.insurer" class="text-field-43 w-input" id="work-Medicaid"
                                            required>
                                            <option value="" disabled>Select Insurer</option>
                                            <option value="MMM">MMM</option>
                                            <option value="Plan De Salud Menonita">Plan De Salud Menonita</option>
                                            <option value="Triple S">Triple S</option>
                                            <option value="First Medical">First Medical</option>
                                        </select>
                                    </div>
                                    <div class="div-block-150">
                                        <label> Enter your insurance ID: </label>
                                    </div>
                                    <input type="text" v-model="data.medicare" class="text-field-15 w-input"
                                        placeholder="Enter your insurance ID" required>
                                    

                                    <div class="div-block-150">
                                        <label> Bin# </label>
                                    </div>
                                    <input type="text" v-model="data.bin" class="text-field-15 w-input"
                                        placeholder="Enter Bin" required>

                                    <div class="div-block-150">
                                        <label> GrupoRX # </label>
                                    </div>
                                    <input type="text" v-model="data.grupo" class="text-field-15 w-input"
                                        placeholder="Enter Grupo" required>

                                    <div class="div-block-150">
                                        <label> Who is your primary doctor? ( Listed under PCP on Vital Card)</label>
                                    </div>
                                    <multiselect  v-model="value" :options="options" :custom-label="nameWithLang" placeholder="Select Doctor" label="name" track-by="name" required></multiselect>
                                    <div class="div-block-150">
                                        <label> Date of Birth </label>
                                    </div>
                                    <div class="div-block-150">
                                        <input type="number" v-model="data.mm" max="12" min="1"
                                            class="text-field-12 w-input" maxlength="2" minlength="2" placeholder="MM"
                                            required>
                                        <input type="number" v-model="data.dd" max="31" min="1"
                                            class="text-field-12 w-input" maxlength="2" minlength="2" placeholder="DD"
                                            required>
                                        <input type="number" v-model="data.yyyy" max="2023" min="1901"
                                            class="text-field-13 w-input" maxlength="4" minlength="4" placeholder="YYYY"
                                            required>
                                    </div>
                                    <div class="div-block-150">
                                        <label> Gender </label>
                                    </div>
                                    <div class="div-block-150">
                                        <select class="text-field-12 w-input" v-model="data.gender">
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                    <div class="div-block-274">
                                        <button type="submit" class="button-22 w-button">Submit</button>
                                    </div>
                                    <div class="" v-if="showError" style="color:red;">
                                        <p>{{ messageErr }}</p>
                                    </div>
                                </form>
                            </div>

                        </transition-group>
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
    <div style="height:450px">
    </div>
    <FooterComponent />
</template>

<script>
import HeaderComponent from '../common/headerComponent.vue'
import FooterComponent from '../common/footerComponent.vue'
import acceptedScreen from '../common/acceptedScreen.vue'
import googleautocomplete from '../common/googleAddressNew.vue'
import loading from '../common/newLoading.vue'
import rejectedScreen from '../common/rejectedScreen.vue'
import Multiselect from 'vue-multiselect'
import doc from './doc.json'
export default {
    name: 'HelloWorld',

    components: {
        HeaderComponent,
        FooterComponent,
        googleautocomplete,
        loading,
        acceptedScreen,
        rejectedScreen,
        Multiselect
    },
    data() {
        return {
            vendorErr: 0,
            doc:'',
            checkrefi: true,
            value:'',
            formatted_address: '',
            active_el: 1,
            sub_ven: 0,
            options: doc,
            id: '',
            data: {
                first_name: '',
                last_name: '',
                phone: '',
                email: 'newform@na.com',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: 'PR',
                zip: '',
                mm: '',
                dd: '',
                yyyy: '',
                dob: '',
                medicare: '',
                gender: 'Male',
                agent: '',
                sub_agent: '',
                grupo: '',
                bin: '',
                insurer: '',
                doctor: ''
            },
            report: '',
            vendor: '',
            showLoadingPage: false,
            accepted: false,
            lastrefi: '',
            rejected: false,
            error: false,
            cashavail: 0,
            wantcash: 0,
            isAddressValid: true,
            eligiblityStatus: 0,
            showEligiblityStatus: false,
            messageErr: '',
            showError: false,
            errmsg: '',
            addressFull: false,
        }
    },
    methods: {
        nameWithLang({ id, name }) {
            if(name=="Others"){
                return name;
            }
            return "Dr. "+ name;
        },
        SearchAddressData() {
            this.messageErr = null;
            this.addressFull = false;
            this.showError = false;
            if(this.data.medicare.substring(0,3) !="008"){
                this.showError = true;
                this.messageErr = "Incorrect Medicaid. Medicaid Id should start with 008.";
                return false;
            }
            if(this.value==""){
                this.showError = true;
                this.messageErr = "Doctor is required.";
                return false;
            }
            
            if (this.data.address_line_1 == "" || this.data.city == "" || this.data.state == "" || this.data.zip == "" || this.data.address_line_1 == null || this.data.state == null || this.data.zip == null || this.data.city == null) {
                this.isAddressValid = false;
                this.showError = true;
                this.addressFull = true;
                this.messageErr = "Invalid Address";
            } else {
                this.showError = false;
                this.checkDuplicate(this.data.medicare);
            }
        },
        checkDuplicate(medi) {
            this.showLoadingPage = true;
            window.axios.post("https://arlo.tycoonmach.net/api/checkmedicaid", {
                medi: medi
            }).then(response => {
                if (response.data.status == "duplicate") {
                    this.showLoadingPage = false;
                    this.messageErr = "Duplicate Lead";
                    this.showError = true;
                } else {
                    this.createLead();
                }
            });
        },
        createLead() {

            this.messageErr = "";
            this.showError = false;
            this.showLoadingPage = true;
            this.data.agent = this.vendor;
            this.data.sub_agent = this.sub_ven;
            if(this.value.id!=0){
                this.data.doctor = this.value.id +" - " + this.value.name;
            }else{
                this.data.doctor = "0 - " + this.doc;
            }
            
            this.data.dob = this.data.mm + "/" + this.data.dd + "/" + this.data.yyyy;
       
           
            window.axios.post("https://arlo.tycoonmach.net/api/website/people/medicaid", this.data).then(response => {
                if (response.data.status == 'success') {
                    this.showLoadingPage = false;
                    window.location.reload();
                } else {
                    this.showLoadingPage = false;
                    this.showError = true;
                    this.messageErr = response.data.message;
                }
            });
        },

        updateLead(pdata) {
            window.axios.post("website/people/update", pdata).then(response => { });
        },
        getAddressData(addressData, placeResultData) {
            console.log(addressData);
            this.formatted_address = placeResultData.formatted_address;
            this.data.city = addressData.locality;
            if (this.data.city == undefined && addressData.administrative_area_level_1 == 'NY') {
                this.data.city = "New York";
            }
            this.data.address_line_1 = this.formatted_address.split(',')[0];
            if (addressData.country == "Puerto Rico") {
                this.data.state = "PR";
            } else {
                this.data.state = addressData.administrative_area_level_1;
            }

            this.data.zip = addressData.postal_code;
            console.log(this.data);
        },

        findKey(addr, type) {
            let comp = addr.find(item => item.types.indexOf(type) >= 0);
            return comp ? comp.short_name : null;
        },
        isNumber(n) {
            return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
        }
    },
    computed: {
        currentDate() {
            const date = new Date().toDateString();
            var dateStrArr = date.split(' ');
            return dateStrArr[1] + " " + dateStrArr[2] + " " + dateStrArr[3];
        }
    },
    created() {
        this.vendor = this.$route.params.vendor;
        if (!this.isNumber(this.vendor)) {
            this.vendorErr = 1;
        }

        this.sub_ven = this.$route.params.sub_vendor;
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('phone')) {
            this.data.phone = urlParams.get('phone')
        }
        if (urlParams.has('first_name')) {
            this.data.first_name = urlParams.get('first_name');
        }
        if (urlParams.has('last_name')) {
            this.data.last_name = urlParams.get('last_name');
        }

    }
}
</script>

<style>.colordiv {
    border: red solid 2px !important;
}</style>
