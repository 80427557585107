<template>
    <div class="wf-section">
    <div class="div-block">
      <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-2 w-nav">
      </div>
      
    </div>
    </div>
</template>
<script>
  export default {
    computed:{
      israteGenie(){
        var baseUrl = window.location.origin;
        if(baseUrl.includes('rategenie')){
          return true;
        }else{
          return false;
        }
      }
    }
  }
</script>