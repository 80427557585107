<template>
<div id="myModal" class="modal">
    <div class="modal-content">
        <span class="close" @click="CloseDialog($event)">&times;</span>
        <p>
            <table id="customers">
                <thead>
                    <tr>
                        <td>First Name</td>
                        <td>Middle Name</td>
                        <td>Last Name</td>
                        <td>Phone</td>
                        <td>City</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="doc in doctorDetails" :key="doc.npi">
                        <td>{{ doc.first_name }}</td>
                        <td>{{ doc.middle_name }}</td>
                        <td>{{ doc.last_name }}</td>
                        <td>{{ doc.phone }}</td>
                        <td>{{ doc.city }}</td>
                        <td><button @click="setDoctor($event, doc)" class="button-22 w-button">Select</button></td>
                    </tr>
                </tbody>
            </table>
        </p>
    </div>
</div>
</template>
<script>

export default {
    props:["doctorDetails"],
  data () {
    return {
        
    }
  },
  methods: {
    CloseDialog(event){
      event.preventDefault();
      this.$emit("closeDialog");
    },
    setDoctor(event , doc){
      event.preventDefault();
      this.$emit("setDoctor", doc);
    }
  },
  created(){
  }
}
</script>
<style>

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  max-width: 886px;
  align-items: center;
  overflow: scroll;
  max-height: 586px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>
