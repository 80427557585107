<template>
<div id="myModal" class="modal">
<div class="modal-content">
  
  <h2>Oops! We get an rejection.</h2>
  <p>{{message}}</p>
  <p style="width:25%l;justify-content: center;"><a :href="'https://prevencioncovid.com/resubmit/'+id" class="button-22 w-button">Click To Resubmit</a></p>
</div>
</div>
</template>

  
<script>
export default {
    props:["message","id"],
    watch: {
        
    },
    data() {
    },
    methods: {
    }
}
</script>
  
<style>
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
</style>

