<template>
    <div>
        <loading v-if="showLoadingPage"></loading>
        <acceptedScreen v-if="accepted" />
        <rejectedScreen v-if="rejected" />
        <HeaderComponent />
        <errorDialog :message="errmessage" :id="id" v-if="showErrorDialog"></errorDialog>
        <docAutocomplete v-if="docAuto" :doctorDetails="allDoc" @setDoctor="setDoctor" @closeDialog="docAuto = false;" />
        <div class="wf-section" style="height:1080px !important;">

            <div class="form-step-2 formimg" style="padding-top:0px;">
                <div class="question-container w-container" style="margin-bottom:25px;">
                    <h1 class="question-2" style="margin-bottom:12px;"><strong class="bold-text-3">Please enter <br
                                class="mobile-newline">info below</strong><br></h1>
                    <p class="text-block-45" style="color:red;font-size:16px;" v-if="vendorErr == 1">Your form link doesn't
                        look correct. Please use the link on your dashboard. <a href="https://dashboard.leadtrack.app/login"
                            target="_blank">Click here</a></p>
                </div>
                <div class="container-10 w-container" style="height:90vh;">
                    <div class="w-form formwithimag">
                        <div class="formblock">
                            <transition-group name="list" tag="div">
                                <div v-if="active_el == 1">
                                    <form @submit.prevent="SearchAddressData">
                                        <div class="div-block-150">
                                            <label> Name </label>
                                        </div>
                                        <div class="div-block-150">
                                            <input type="text" class="text-field-12 w-input" maxlength="256"
                                                v-model="data.first_name" data-name="First Name" placeholder="First Name"
                                                required>
                                            <input type="text" class="text-field-13 w-input" maxlength="256"
                                                v-model="data.last_name" data-name="Last Name" placeholder="Last Name"
                                                id="Last-Name" required>
                                        </div>
                                        <div class="div-block-150">
                                            <label> Phone </label>
                                        </div>
                                        <input type="number" v-model="data.phone" class="text-field-15 w-input"
                                            placeholder="Enter Phone" required>
                                        <div v-if="!showBlk">
                                            <div class="div-block-150">
                                                <label> Address </label>
                                            </div>
                                            <autocomplete @setAddress="setAddress" @noAddressFound="noAddressFound()" />
                                            <a href="#" @click="showBlock($event)">Enter Address Manually</a>
                                        </div>
                                        <div v-if="showBlk">
                                            <div class="div-block-150">
                                                <label> Enter Full Address </label>
                                            </div>
                                            <div class="div-block-150">
                                                <select class="text-field-12 w-input" v-model="data.address_type" required>
                                                    <option value="" selected disabled> Select Address Type</option>
                                                    <option value="1"> Home Address </option>
                                                    <option value="2"> HC Box </option>
                                                    <option value="3"> PO Box </option>
                                                    <option value="4"> RR Box </option>
                                                </select>

                                                <input v-if="data.address_type == 3" type="number"
                                                    class="text-field-12 w-input" maxlength="256" v-model="data.box"
                                                    data-name="Addres" placeholder="PO Box #" required>
                                            </div>
                                            <div class="div-block-150" v-if="data.address_type == 2">

                                                <input type="number" class="text-field-13 w-input" maxlength="256"
                                                    v-model="data.hc" data-name="Last Name" placeholder="HC # "
                                                    id="House Number" required>
                                                <input type="text" class="text-field-12 w-input" maxlength="256"
                                                    v-model="data.box" data-name="Addres" placeholder="Box #" required>
                                            </div>

                                            <div class="div-block-150" v-if="data.address_type == 4">

                                                <input type="number" class="text-field-13 w-input" maxlength="256"
                                                    v-model="data.hc" data-name="Last Name" placeholder="RR # "
                                                    id="House Number" required>
                                                <input type="text" class="text-field-12 w-input" maxlength="256"
                                                    v-model="data.box" data-name="Addres" placeholder="Box #" required>
                                            </div>
                                            <div class="div-block-150" v-if="data.address_type == 1">

                                                <input type="text" class="text-field-13 w-input" maxlength="256"
                                                    v-model="data.house_no" data-name="Last Name" placeholder="House Number"
                                                    id="House Number" required>
                                                <input type="text" class="text-field-13 w-input" maxlength="256"
                                                    v-model="data.street_name" data-name="Last Name"
                                                    placeholder="Street Name" id="Street Name" required>
                                            </div>
                                            <div class="div-block-150" v-if="data.address_type == 1">
                                                <input type="text" class="text-field-12 w-input" maxlength="256"
                                                    v-model="data.address_line_2" data-name="Addres" placeholder="APT #">
                                                <input type="text" list="citiesq" class="text-field-13 w-input"
                                                    maxlength="256" v-model="data.city" data-name="Last Name"
                                                    placeholder="City" id="Last-Name" required>
                                                <datalist id="citiesq">
                                                    <option value="Adjuntas"></option>
                                                    <option value="Aguada"></option>
                                                    <option value="Aguadilla"></option>
                                                    <option value="Aguas Buenas"></option>
                                                    <option value="Aibonito"></option>
                                                    <option value="Anasco"></option>
                                                    <option value="Arecibo"></option>
                                                    <option value="Arroyo"></option>
                                                    <option value="Barceloneta"></option>
                                                    <option value="Barranquitas"></option>
                                                    <option value="Bayamon"></option>
                                                    <option value="Cabo Rojo"></option>
                                                    <option value="Caguas"></option>
                                                    <option value="Camuy"></option>
                                                    <option value="Canovanas"></option>
                                                    <option value="Carolina"></option>
                                                    <option value="Catano"></option>
                                                    <option value="Cayey"></option>
                                                    <option value="Ceiba"></option>
                                                    <option value="Ciales"></option>
                                                    <option value="Cidra"></option>
                                                    <option value="Coamo"></option>
                                                    <option value="Comerio"></option>
                                                    <option value="Corozal"></option>
                                                    <option value="Culebra"></option>
                                                    <option value="Dorado"></option>
                                                    <option value="Fajardo"></option>
                                                    <option value="Florida"></option>
                                                    <option value="Guanica"></option>
                                                    <option value="Guayama"></option>
                                                    <option value="Guayanilla"></option>
                                                    <option value="Guaynabo"></option>
                                                    <option value="Gurabo"></option>
                                                    <option value="Hatillo"></option>
                                                    <option value="Hormigueros"></option>
                                                    <option value="Humacao"></option>
                                                    <option value="Isabela"></option>
                                                    <option value="Jayuya"></option>
                                                    <option value="Juana Diaz"></option>
                                                    <option value="Juncos"></option>
                                                    <option value="Lajas"></option>
                                                    <option value="Lares"></option>
                                                    <option value="Las Marias"></option>
                                                    <option value="Las Piedras"></option>
                                                    <option value="Loiza"></option>
                                                    <option value="Luquillo"></option>
                                                    <option value="Manati"></option>
                                                    <option value="Maricao"></option>
                                                    <option value="Maunabo"></option>
                                                    <option value="Mayaguez"></option>
                                                    <option value="Moca"></option>
                                                    <option value="Morovis"></option>
                                                    <option value="Naguabo"></option>
                                                    <option value="Naranjito"></option>
                                                    <option value="Orocovis"></option>
                                                    <option value="Patillas"></option>
                                                    <option value="Penuelas"></option>
                                                    <option value="Ponce"></option>
                                                    <option value="Quebradillas"></option>
                                                    <option value="Rincon"></option>
                                                    <option value="Rio Grande"></option>
                                                    <option value="Sabana Grande"></option>
                                                    <option value="Salinas"></option>
                                                    <option value="San German"></option>
                                                    <option value="San Juan"></option>
                                                    <option value="San Lorenzo"></option>
                                                    <option value="San Sebastian"></option>
                                                    <option value="Santa Isabel"></option>
                                                    <option value="Toa Alta"></option>
                                                    <option value="Toa Baja"></option>
                                                    <option value="Trujillo Alto"></option>
                                                    <option value="Utuado"></option>
                                                    <option value="Vega Alta"></option>
                                                    <option value="Vega Baja"></option>
                                                    <option value="Vieques"></option>
                                                    <option value="Villalba"></option>
                                                    <option value="Yabucoa"></option>
                                                    <option value="Yauco"></option>
                                                </datalist>
                                            </div>
                                            <input type="text" class="text-field-13 w-input" maxlength="256"
                                                v-if="data.address_type >= 2" v-model="data.city" data-name="Last Name"
                                                placeholder="City" list="cities" id="Last-Name" required>
                                            <datalist id="cities">
                                                <option value="Adjuntas"></option>
                                                <option value="Aguada"></option>
                                                <option value="Aguadilla"></option>
                                                <option value="Aguas Buenas"></option>
                                                <option value="Aibonito"></option>
                                                <option value="Anasco"></option>
                                                <option value="Arecibo"></option>
                                                <option value="Arroyo"></option>
                                                <option value="Barceloneta"></option>
                                                <option value="Barranquitas"></option>
                                                <option value="Bayamon"></option>
                                                <option value="Cabo Rojo"></option>
                                                <option value="Caguas"></option>
                                                <option value="Camuy"></option>
                                                <option value="Canovanas"></option>
                                                <option value="Carolina"></option>
                                                <option value="Catano"></option>
                                                <option value="Cayey"></option>
                                                <option value="Ceiba"></option>
                                                <option value="Ciales"></option>
                                                <option value="Cidra"></option>
                                                <option value="Coamo"></option>
                                                <option value="Comerio"></option>
                                                <option value="Corozal"></option>
                                                <option value="Culebra"></option>
                                                <option value="Dorado"></option>
                                                <option value="Fajardo"></option>
                                                <option value="Florida"></option>
                                                <option value="Guanica"></option>
                                                <option value="Guayama"></option>
                                                <option value="Guayanilla"></option>
                                                <option value="Guaynabo"></option>
                                                <option value="Gurabo"></option>
                                                <option value="Hatillo"></option>
                                                <option value="Hormigueros"></option>
                                                <option value="Humacao"></option>
                                                <option value="Isabela"></option>
                                                <option value="Jayuya"></option>
                                                <option value="Juana Diaz"></option>
                                                <option value="Juncos"></option>
                                                <option value="Lajas"></option>
                                                <option value="Lares"></option>
                                                <option value="Las Marias"></option>
                                                <option value="Las Piedras"></option>
                                                <option value="Loiza"></option>
                                                <option value="Luquillo"></option>
                                                <option value="Manati"></option>
                                                <option value="Maricao"></option>
                                                <option value="Maunabo"></option>
                                                <option value="Mayaguez"></option>
                                                <option value="Moca"></option>
                                                <option value="Morovis"></option>
                                                <option value="Naguabo"></option>
                                                <option value="Naranjito"></option>
                                                <option value="Orocovis"></option>
                                                <option value="Patillas"></option>
                                                <option value="Penuelas"></option>
                                                <option value="Ponce"></option>
                                                <option value="Quebradillas"></option>
                                                <option value="Rincon"></option>
                                                <option value="Rio Grande"></option>
                                                <option value="Sabana Grande"></option>
                                                <option value="Salinas"></option>
                                                <option value="San German"></option>
                                                <option value="San Juan"></option>
                                                <option value="San Lorenzo"></option>
                                                <option value="San Sebastian"></option>
                                                <option value="Santa Isabel"></option>
                                                <option value="Toa Alta"></option>
                                                <option value="Toa Baja"></option>
                                                <option value="Trujillo Alto"></option>
                                                <option value="Utuado"></option>
                                                <option value="Vega Alta"></option>
                                                <option value="Vega Baja"></option>
                                                <option value="Vieques"></option>
                                                <option value="Villalba"></option>
                                                <option value="Yabucoa"></option>
                                                <option value="Yauco"></option>
                                            </datalist>
                                            <div class="div-block-150" v-if="data.address_type >= 1">
                                                <input type="text" class="text-field-12 w-input" maxlength="2"
                                                    v-model="data.state" data-name="State" placeholder="State" required>
                                                <input type="text" class="text-field-13 w-input" maxlength="5"
                                                    v-model="data.zip" data-name="Last Name" placeholder="Zip"
                                                    id="Last-Name" required>
                                            </div>
                                        </div>
                                        <div class="div-block-150">
                                            <label> Select Insurer </label>
                                        </div>
                                        <div class="div-block-150">
                                            <select v-model="data.insurer" class="text-field-43 w-input" id="work-Medicaid"
                                                required>
                                                <option value="" disabled>Select Insurer</option>
                                                <option value="MMM">MMM</option>
                                                <option value="Plan De Salud Menonita">Plan De Salud Menonita</option>
                                                <option value="Triple S">Triple S</option>
                                                <option value="First Medical">First Medical</option>
                                                <option value="MSC">MCS</option>
                                            </select>
                                        </div>
                                        <div class="div-block-150">
                                            <label> Enter your insurance ID: (Should start with 008 and 13 digits
                                                long)</label>
                                        </div>
                                        <input type="text" v-model="data.medicare" class="text-field-15 w-input"
                                            placeholder="Enter your insurance ID" maxlength="13" required>

                                        <div class="div-block-150">
                                            <label> Who is your primary doctor? (Listed under PCP on Vital Card)</label>
                                        </div>

                                        <multiselect v-model="value" :options="options" :custom-label="nameWithLang"
                                            placeholder="Select Doctor" label="name" track-by="name" required></multiselect>
                                        <div class="div-block-150" style="margin-top: 15px;" v-if="value.id == 0">
                                            <br />
                                            <input type="text" class="text-field-12 w-input" maxlength="256"
                                                v-model="dr_first" data-name="" placeholder="First Name" required>
                                            <input type="text" class="text-field-12 w-input" maxlength="256"
                                                v-model="dr_first_last" data-name="" placeholder="First Last Name">
                                            <input type="text" class="text-field-13 w-input" maxlength="256"
                                                v-model="dr_last" data-name="" placeholder="Second Last Name" id="Last-Name"
                                                required>
                                        </div>
                                        <div class="div-block-150" style="margin-top: 15px;" v-if="value.id == 0">
                                            <br />
                                            <a href="#" @click="SearchDoctor($event)">Search</a>

                                        </div>
                                        <div class="div-block-150" style="margin-top: 15px;" v-if="value.id == 0">
                                            <br />
                                            <span v-if="showError" style="color:red">No Doctor found with that name.</span>
                                        </div>
                                        <div class="div-block-150" style="padding-top: 20px;">
                                            <label> Date of Birth </label>
                                        </div>
                                        <div class="div-block-150">
                                            <select class="text-field-12 w-input" v-model="data.mm" required>
                                                <option disabled value="">Month</option>
                                                <option value="1"> January</option>
                                                <option value="2"> February</option>
                                                <option value="3"> March</option>
                                                <option value="4"> April</option>
                                                <option value="5"> May</option>
                                                <option value="6"> June</option>
                                                <option value="7"> July</option>
                                                <option value="8"> August</option>
                                                <option value="9"> September</option>
                                                <option value="10"> October</option>
                                                <option value="11"> November</option>
                                                <option value="12"> December</option>
                                            </select>

                                            <input type="number" v-model="data.dd" max="31" min="1"
                                                class="text-field-12 w-input" maxlength="2" minlength="2" placeholder="DD"
                                                required>
                                            <input type="number" v-model="data.yyyy" max="2023" min="1901"
                                                class="text-field-13 w-input" maxlength="4" minlength="4" placeholder="YYYY"
                                                required>
                                        </div>

                                        <div class="div-block-150">
                                            <label> Gender </label>
                                        </div>
                                        <div class="div-block-150">
                                            <select class="text-field-12 w-input" v-model="data.gender">
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                        <div class="div-block-274">
                                            <button type="submit" class="button-22 w-button">Submit</button>
                                        </div>
                                        <div class="" v-if="showError" style="color:red;">
                                            <p>{{ messageErr }}</p>
                                        </div>
                                        <div class="" v-if="address_val" style="color:red;">
                                            <p>{{ errorAddressDescription }}</p>
                                        </div>

                                    </form>
                                </div>

                            </transition-group>
                        </div>
                        <div class="imageblock">
                            <span v-if="data.insurer != ''"> Sample Card </span>
                            <img src="@/assets/cards/medcard_mmm.jpg" v-if="data.insurer == 'MMM'" />
                            <img src="@/assets/cards/medcard_menonita.jpg"
                                v-if="data.insurer == 'Plan De Salud Menonita'" />
                            <img src="@/assets/cards/medcard_sss.jpg" v-if="data.insurer == 'Triple S'" />
                            <img src="@/assets/cards/medcard_first.jpg" v-if="data.insurer == 'First Medical'" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="height:450px">
    </div>
    <FooterComponent />
</template>

<script>
import HeaderComponent from '../common/headerComponent.vue'
import FooterComponent from '../common/footerComponent.vue'
import acceptedScreen from '../common/acceptedScreen.vue'
import googleautocomplete from '../common/googleAddressNew.vue'
import loading from '../common/newLoading.vue'
import rejectedScreen from '../common/rejectedScreen.vue'
import Multiselect from 'vue-multiselect'
import autocomplete from './autocomplete.vue'
import errorDialog from './errorDialog.vue'
import doc from './doc.json'
import docAutocomplete from './docAutocomplete.vue';
export default {
    name: 'HelloWorld',

    components: {
        HeaderComponent,
        FooterComponent,
        googleautocomplete,
        loading,
        acceptedScreen,
        rejectedScreen,
        Multiselect,
        autocomplete,
        docAutocomplete,
        errorDialog
    },
    data() {
        return {
            showBlk: false,
            vendorErr: 0,
            id: 0,
            drfirst_name: '',
            drlast_name: '',
            errorAddressDescription: '',
            checkrefi: true,
            value: '',
            formatted_address: '',
            active_el: 1,
            showErrorDialog: false,
            sub_ven: 0,
            options: doc,
            dr_first: "",
            dr_first_last: "",
            dr_last: "",
            id: '',
            showError: false,
            allDoc: [],
            address_val: false,
            address_try: 0,
            data: {
                first_name: '',
                last_name: '',
                phone: '',
                email: 'newform@na.com',
                address_type: '',
                hc: '',
                box: '',
                urban: '',
                house_no: '',
                street_no: '',
                street_name: '',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: 'PR',
                zip: '',
                mm: '',
                dd: '',
                yyyy: '',
                dob: '',
                medicare: '',
                gender: 'Male',
                agent: '',
                sub_agent: '',
                grupo: '',
                bin: '',
                insurer: '',
                doctor: '',
                address_valid: 0
            },
            report: '',
            docAuto: false,
            vendor: '',
            showLoadingPage: false,
            accepted: false,
            lastrefi: '',
            rejected: false,
            error: false,
            cashavail: 0,
            wantcash: 0,
            isAddressValid: true,
            eligiblityStatus: 0,
            showEligiblityStatus: false,
            messageErr: '',
            showError: false,
            errmsg: '',
            addressFull: false,
            npi: 0,
            found: false,
            errmessage: [],
        }
    },
    watch: {
        value: function (newVal, oldVal) {
            this.checkDoc(newVal);
        },
    },
    methods: {
        noAddressFound() {
            this.showBlk = true;
            this.data.address_line_1 = "";
            this.data.address_line_2 = "";
        },
        showBlock() {
            this.showBlk = true;
            this.data.address_line_1 = "";
            this.data.address_line_2 = "";
        },
        nameWithLang({
            id,
            name_with_city
        }) {
            if (name_with_city == "Others") {
                return name_with_city;
            }
            return "Dr. " + name_with_city;
        },
        setAddress(address) {
            this.data.address_line_1 = address.address;
            this.data.address_line_2 = address.address2;
            this.data.city = address.city;
            this.data.state = address.state2;
            this.data.zip = address.szip;
        },
        SearchAddressData() {
            this.messageErr = null;
            this.addressFull = false;
            this.showError = false;
            if (this.data.medicare.substring(0, 3) != "008") {
                this.showError = true;
                this.messageErr = "Incorrect Medicaid. Medicaid Id should start with 008.";
                return false;
            }
            if (this.value == "") {
                this.showError = true;
                this.messageErr = "Doctor is required.";
                return false;
            }

            if (this.showBlk) {
                if (this.data.address_type == 1) {
                    this.data.address_line_1 = this.data.house_no + " " + this.data.street_name;
                } else if (this.data.address_type == 2) {
                    this.data.address_line_1 = "HC " + this.data.hc + " Box " + this.data.box;
                } else if (this.data.address_type == 4) {
                    this.data.address_line_1 = "RR " + this.data.hc + " Box " + this.data.box;
                } else if (this.data.address_type == 3) {
                    this.data.address_line_1 = "PO Box  " + this.data.box;
                }
            }

            /*if ( this.data.address_line_2 !== undefined &&  this.data.address_line_2 !== null &&  this.data.address_line_2 !== "" &&  this.data.address_line_2 !== " ") {
                    this.data.address_line_2 = this.data.address_line_2;
            }*/



            if (this.data.address_line_1 == "" || this.data.city == "" || this.data.state == "" || this.data.zip == "" || this.data.address_line_1 == null || this.data.state == null || this.data.zip == null || this.data.city == null) {
                this.isAddressValid = false;
                this.showError = true;
                this.addressFull = true;
                this.messageErr = "Invalid Address";
            } else {
                this.showError = false;
                this.checkDuplicate(this.data.medicare);
            }
        },
        checkDuplicate(medi) {
            this.showLoadingPage = true;

            window.axios.post("https://arlo.tycoonmach.net/api/checkmedicaid", {
                medi: medi
            }).then(response => {
                if (response.data.status == "duplicate") {
                    this.showLoadingPage = false;
                    this.messageErr = "Duplicate Lead";
                    this.showError = true;
                } else {
                    this.validateAddress();
                }
            });
        },
        setDoctor(doc) {
            this.data.doctor = doc.npi + ":" + doc.first_name + "|" + doc.last_name + ":" + doc.lisc + ":" + doc.phone;
            this.npi = doc.npi;
            this.docAuto = false;
        },
        checkDoc(newVal) {
            if (newVal.id != 0) {
                this.data.doctor = newVal.id + ":" + newVal.first_name + "|" + newVal.last_name + ":" + newVal.lisence + ":" + newVal.phone;
                this.npi = newVal.id;
            } else {
                this.npi = 0;
            }
        },
        SearchDoctor(event) {
            event.preventDefault();
            this.showError = false;
            this.docAuto = false;
            this.showLoadingPage = true;
            this.allDoc = [];
            let url = "https://cdn.tycoonmach.net/api/doc_search";
            let last_name = this.dr_first_last + " " + this.dr_last;
            last_name = last_name.trim();
            const descTex = ["Internal Medicine", "Family Medicine", "General Practice"];
            let data = {
                "firstName": this.dr_first,
                "lastName": last_name
            }
            let vm = this;
            window.axios.post(url, data).then(resp => {
                if (resp.data.length > 0) {
                    let response = resp.data;
                    response.forEach(element => {
                        if (descTex.includes(element.primaryTaxonomy.desc)) {
                            vm.allDoc.push({
                                "npi": element.number,
                                "first_name": element.basic.firstName,
                                "last_name": element.basic.lastName,
                                "middle_name": element.basic.middleName,
                                "phone": element.primaryAddress.teleNumber,
                                "lisc": element.primaryTaxonomy.license,
                                "city": element.primaryAddress.city,
                            })
                        }

                    });
                    if (vm.allDoc.length > 0) {
                        this.docAuto = true;
                        console.log(vm.allDoc);
                    } else {
                        this.showError = true;
                    }
                } else {
                    this.showError = true;
                }
                this.showLoadingPage = false;
            });
        },
        validateAddress() {
            this.address_try++;
            if (this.address_try > 2) {
                this.createLead();
            } else {
                this.errorAddressDescription = "";
                window.axios.post("https://arlo.tycoonmach.net/api/validate-address", this.data).then(response => {
                    try {
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(response.data, 'text/xml');
                        const errorElement = xmlDoc.querySelector('Address Error');
                        if (errorElement !== null) {
                            this.errorAddressDescription = "Address Error: " + errorElement.querySelector('Description').textContent;
                            this.address_val = true;
                            this.showLoadingPage = false;
                        } else {
                            this.data.address_line_1 = xmlDoc.querySelector('Address2').textContent;
                            this.data.city = xmlDoc.querySelector('City').textContent;
                            this.data.zip = xmlDoc.querySelector('Zip5').textContent;
                            this.data.address_valid = 1;
                            this.createLead();
                        }
                    } catch (error) {
                        this.createLead();
                    }
                });
            }
        },
        createLead() {            
            if (this.npi == 0) {
                this.messageErr = "A vaid doctor name is required.";
                this.showError = true;
                this.showLoadingPage = false;
                return false;
            }
            this.messageErr = "";
            this.showError = false;
            this.showLoadingPage = true;
            this.data.agent = this.vendor;
            this.data.sub_agent = this.sub_ven;

            let dob = this.data.yyyy
            if (parseInt(this.data.dd) < 10) {
                dob = "0" + parseInt(this.data.dd) + "/" + dob
            } else {
                dob = this.data.dd + "/" + dob
            }

            if (parseInt(this.data.mm) < 10) {
                dob = "0" + parseInt(this.data.mm) + "/" + dob
            } else {
                dob = this.data.mm + "/" + dob
            }
            this.data.dob = dob;
            window.axios.post("https://arlo.tycoonmach.net/api/website/people/medicaid", this.data).then(response => {
                if (response.data.status == 'success') {
                    this.id = response.data.people_id;
                    this.placeOrder();
                } else {
                    this.showLoadingPage = false;
                    this.showError = true;
                    this.messageErr = response.data.message;
                }
            });
        },

        placeOrder() {
            let url = "https://cdn.tycoonmach.net/api/priciption/" + this.id;
            let vm = this;
            window.axios.get(url).then(response => {
                setInterval(function () {
                    vm.checkStatus()
                }, 60 * 1000);
            });
        },
        getResponseOfForm() {
            let url = 'https://apply.freerapidcovidkits.com/api/website/getOrderStatus/' + this.id;
            window.axios.get(url).then(response => {
                if (response.data.status == "success") {
                    if (response.data.errors != null) {
                        let errors = response.data.errors.status;
                        this.found = true;
                        if (errors == "Unbilled") {
                            let dobError = ["09", "0255", "832", "0833", "2807", "7D"];
                            let docError = ["25", "71", "826", "0205", "0206", "0209", "0204", "0237", "1801", "0207", "1026", "71", "72", "35"];
                            let medError = ["07", "51", "52", "69", "68"];
                            let temp = response.data.errors.extra_details.split(",");
                            let errors = temp;
                            errors.forEach((element) => {
                                if (docError.includes(element.trim())) {
                                    this.errmessage.push("Doctor is incorrect.");
                                }
                                if (medError.includes(element.trim())) {
                                    this.errmessage.push("Medicaid Id is incorrect");
                                }
                                if (dobError.includes(element.trim())) {
                                    this.errmessage.push("Date of birth is incorrect.");
                                }
                            });
                            this.showErrorDialog = true;
                            this.showLoadingPage = false;
                        } else if (errors == "Billed") {
                            window.location.reload();
                        }
                    }
                }
            });
        },
        checkStatus() {
            let k = this;
            if (!this.found) {
                this.getResponseOfForm();
            }
        },

        updateLead(pdata) {
            window.axios.post("website/people/update", pdata).then(response => { });
        },
        getAddressData(addressData, placeResultData) {
            console.log(addressData);
            this.formatted_address = placeResultData.formatted_address;
            this.data.city = addressData.locality;
            if (this.data.city == undefined && addressData.administrative_area_level_1 == 'NY') {
                this.data.city = "New York";
            }
            this.data.address_line_1 = this.formatted_address.split(',')[0];
            if (addressData.country == "Puerto Rico") {
                this.data.state = "PR";
            } else {
                this.data.state = addressData.administrative_area_level_1;
            }

            this.data.zip = addressData.postal_code;
            console.log(this.data);
        },

        findKey(addr, type) {
            let comp = addr.find(item => item.types.indexOf(type) >= 0);
            return comp ? comp.short_name : null;
        },
        isNumber(n) {
            return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
        }
    },
    computed: {
        currentDate() {
            const date = new Date().toDateString();
            var dateStrArr = date.split(' ');
            return dateStrArr[1] + " " + dateStrArr[2] + " " + dateStrArr[3];
        }
    },
    created() {
        this.vendor = this.$route.params.vendor;
        if (!this.isNumber(this.vendor)) {
            this.vendorErr = 1;
        }

        this.sub_ven = this.$route.params.sub_vendor;
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('phone')) {
            this.data.phone = urlParams.get('phone')
        }
        if (urlParams.has('first_name')) {
            this.data.first_name = urlParams.get('first_name');
        }
        if (urlParams.has('last_name')) {
            this.data.last_name = urlParams.get('last_name');
        }

    }
}
</script>

<style>.colordiv {
    border: red solid 2px !important;
}

.imageblock {
    padding-left: 20px;
    width: 50%;
}

.formblock {
    width: 65%;
}

.w-form.formwithimag {
    display: flex;
    align-items: center;
    width: 100%;
}

.formimg .container-10 {
    margin-bottom: 20px;
    padding-right: 0%;
    padding-left: 0%;
}</style>
